import { getJSON, postJSON } from '../../helpers/requests';
import { API_URL } from './../../config';

class UserModel {
   async getUser(id) {
      const getUserUrl = `${API_URL}/users/${id}`;
      const user = await getJSON(getUserUrl);
      return user;
   }

   async downloadDeck(id) {
      const downloadDeckUrl = `${API_URL}/public/${id}`;
      const deck = await getJSON(downloadDeckUrl);
      return deck;
   }

   async followUnfollow(type, id) {
      const socialUrl = `${API_URL}/socials/${id}/${type}`;
      const followUnfollowUser = await postJSON(socialUrl);
      return followUnfollowUser;
   }

   async loadWeeklyVersus(id) {
      const getWeeklyVersusUrl = `${API_URL}/activity/versus/${id}`;
      const data = await getJSON(getWeeklyVersusUrl);
      return data;
   }
}

export default new UserModel();
