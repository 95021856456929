import { loadGraph, generateLegend } from '../../helpers/loadGraph';

class AppView {
   #weeklyChart;

   logOutListener(handler) {
      const logoutBtn = document.querySelector(
         'section.left .bottom button.log-out'
      );

      logoutBtn.addEventListener('click', handler);
   }

   createDeck(handler) {
      const createDeckBtn = document.querySelector('section.left .create-deck');
      createDeckBtn.addEventListener('click', handler);
   }

   loadRightSideOfDashboard() {
      const firstName = localStorage.getItem('firstName');
      const lastName = localStorage.getItem('lastName');
      const imageUrl = localStorage.getItem('imageUrl');
      const email = localStorage.getItem('email');
      const streak = localStorage.getItem('streak');
      const experience = localStorage.getItem('experience');
      const cardsStudied = localStorage.getItem('cardsStudied');

      const imageUrlEl = document.querySelector('section.right img');
      const firstNameEl = document.querySelector('section.right .user-name');
      const streakEl = document.querySelector('section.right .streak');
      const experienceEl = document.querySelector('section.right .experience');

      imageUrlEl.setAttribute('src', imageUrl);
      firstNameEl.textContent = `@${firstName}`;
      streakEl.textContent = streak;
      experienceEl.textContent = experience;
   }

   hideGraph() {
      document.querySelector('section.right .graph').style.display = 'none';
      document.querySelector(
         'section.right .not-following-anyone'
      ).style.display = 'flex';
   }

   renderGraphFriends(users) {
      const graphUserList = document.querySelector(
         'section.right .friend-selector ul.bottom-box'
      );

      graphUserList.innerHTML = '';

      users.forEach(user => {
         const html = this.#generateGraphUserHtml(user.isFollowing);

         graphUserList.insertAdjacentHTML('beforeend', html);
      });
   }

   #generateGraphUserHtml(user) {
      const { id, firstName, lastName, imageUrl } = user;

      return `<li class="user" data-id="${id}">
                        <img src="${imageUrl}" />
                        <p>${firstName} ${lastName}</p>
               </li>`;
   }

   friendSelectorGraph(handler) {
      const friendSelector = document.querySelector(
         'section.right .friend-selector'
      );

      const listOfFriends = friendSelector.querySelector('ul');

      const horizontalPathSvg = friendSelector.querySelector('path.horizontal');
      const verticalPathSvg = friendSelector.querySelector('path.vertical');

      const topUserImg = friendSelector.querySelector('.user img');
      const topUserName = friendSelector.querySelector('.user p');

      friendSelector.addEventListener('click', e => {
         const topBoxClicked = e.target.closest('.top-box');
         const userClicked = e.target.closest('li.user');

         if (!topBoxClicked && !userClicked) return;

         if (userClicked) {
            const userClickedImg = userClicked.querySelector('img').src;
            const userClickedName = userClicked.querySelector('p').textContent;

            topUserImg.src = userClickedImg;
            topUserName.textContent = userClickedName;

            const { id } = userClicked.dataset;

            const allFriends = [...friendSelector.querySelectorAll('li')];
            allFriends.forEach(friend => friend.classList.remove('active'));
            userClicked.classList.add('active');

            handler(id);
         }

         if (topBoxClicked) {
            friendSelector.classList.toggle('active');
            listOfFriends.classList.toggle('active');
            horizontalPathSvg.classList.toggle('active');
            verticalPathSvg.classList.toggle('active');
         }
      });
   }

   startingPointForGraph(user) {
      const friendSelector = document.querySelector(
         'section.right .friend-selector'
      );

      const allFriends = [...friendSelector.querySelectorAll('li')];

      const topUserImg = friendSelector.querySelector('.user img');
      const topUserName = friendSelector.querySelector('.user p');

      const { id, firstName, lastName, imageUrl } = user.isFollowing;

      // Loop through the allFriends list and find which one is related to this starting point user --> we then make that element active
      const [activeEl] = allFriends.filter(friend => friend.dataset.id === id);
      activeEl.classList.add('active');

      topUserImg.src = imageUrl;
      topUserName.textContent = `${firstName} ${lastName}`;
   }

   weeklyVersusGraph(data) {
      console.log(data);
      const graphEl = document.querySelector('section.right #graph');

      if (this.#weeklyChart) this.#weeklyChart.destroy();

      this.#weeklyChart = loadGraph(graphEl, data);

      const legendEl = document.querySelector(
         'section.right #legend-container'
      );

      generateLegend(this.#weeklyChart, legendEl);
   }

   adjustProfilePath() {
      const profileNav = document.querySelector('section.left nav a.profile');
      const profileRightSide = document.querySelector(
         'section.right .top a.top'
      );

      const currUserId = window.localStorage.getItem('id');

      const pathToProfile = `#user/${currUserId}`;

      profileNav.setAttribute('href', pathToProfile);
      profileRightSide.setAttribute('href', pathToProfile);
   }
}

export default new AppView();
