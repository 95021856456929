import waitInSeconds from '../../helpers/waitInSeconds';

class CardView {
   #cardsToStudy = [];
   #cardId;
   #deck;
   #studyAheadForm = document.querySelector('form.study-ahead');
   #studyAheadFormInputs = this.#studyAheadForm.querySelectorAll('input');

   renderCardBaseHtml() {
      const middleContent = document.querySelector('section.middle');
      middleContent.innerHTML = this.#cardBaseHtml;

      middleContent.style.marginBottom = 0;
      middleContent.style.padding = 0;

      this.#pageLayoutStudyMode();
   }

   keyDownListener() {
      window.addEventListener('keydown', e => {
         if (this.#cardsToStudy.length === 0) return;

         const showButton = document.querySelector(
            'section.middle .card-page button.show-answer'
         );

         const easyBtn = document.querySelector(
            'section.middle .card-page button.easy'
         );

         const hardBtn = document.querySelector(
            'section.middle .card-page button.hard'
         );

         // If showButton is not present, then we're not on the card-page
         if (!showButton) return;

         // If space is clicked, we either click the show button or the correct button
         if (e.key === ' ') {
            // If showButton is not hidden, then we simulate a click
            if (!showButton.classList.contains('hidden'))
               return showButton.click();

            // If the showButton is hidden, then we click the easy button
            return easyBtn.click();
         }

         // If the show button isn't hidden, then we don't run any of the code below
         if (!showButton.classList.contains('hidden')) return;

         // If the show button is hidden, then the answer buttons are visible

         // If 1 is pressed, hardBtn is clicked
         if (e.key === '1') return hardBtn.click();

         // If 2 is pressed, easyBtn is clicked
         if (e.key === '2') return easyBtn.click();
      });
   }

   clickAnywhereListener() {
      const cardPage = document.querySelector('section.middle .card-page');

      const showButton = document.querySelector(
         'section.middle .card-page button.show-answer'
      );

      const easyBtn = document.querySelector(
         'section.middle .card-page button.easy'
      );

      const hardBtn = document.querySelector(
         'section.middle .card-page button.hard'
      );

      cardPage.addEventListener('click', e => {
         if (this.#cardsToStudy.length === 0) return;

         const goBackBtn = e.target.closest('a.back');
         const button = e.target.closest('button');

         const widthOfPage =
            document.documentElement.getBoundingClientRect().width;

         const clickPercentage = (e.clientX / widthOfPage) * 100;

         // If goBackBtn exist, then the user is leaving the page (going back), so let's not do anything
         // Or if the user is clicking a button, don't do anything here
         if (goBackBtn || button) return;

         // If the show button is hidden, then the answer buttons are visible, so let's see which side of the page the're clicking
         if (showButton.classList.contains('hidden')) {
            // If they are clicking on the right side of the page
            if (clickPercentage >= 50) easyBtn.click();

            // If they are clicking on the left side of the page
            if (clickPercentage < 50) hardBtn.click();

            return;
         }

         // If the show button is hidden, let's show it
         showButton.click();
      });
   }

   adjustHtml(deck) {
      this.#deck = deck;

      // const nameDeck = document.querySelector('.name-deck');
      const backBtn = document.querySelector(
         'section.middle .card-page a.back'
      );

      backBtn.setAttribute('href', `./app.html#decks/${this.#deck.id}`);

      // nameDeck.textContent = deck.name;

      const reviewCardsEl = document.querySelector(
         'section.middle .card-page li h5.review-cards span'
      );

      const newCardsEl = document.querySelector(
         'section.middle .card-page li h5.new-cards span'
      );

      const { dueCards, urgentlyDueCards, newCards } = deck.stats;

      newCardsEl.textContent = newCards.results;
      reviewCardsEl.textContent = dueCards.results + urgentlyDueCards.results;
   }

   setPoints() {
      document.querySelector(
         'section.middle .card-page .top .experience'
      ).innerHTML = `💎 <span class=>${this.#deck.experience}</span>`;
   }

   // setStudyCards is called when all of the reviewable cards are loaded from database
   setStudyCards(cards) {
      this.#cardsToStudy = cards;
      this.#adjustCardHtml();
   }

   // Adjusts the front and back of the current shown card
   #adjustCardHtml() {
      const cardEl = document.querySelector('section.middle .card-page .card');
      const front = cardEl.querySelector('.front');
      const back = cardEl.querySelector('.back');

      cardEl.classList.remove('skeleton-container');
      back.classList.remove('active');

      const currentCard = this.#cardsToStudy[0];

      this.#cardId = currentCard._id;

      cardEl.setAttribute('data-id', this.#cardId);
      front.textContent = currentCard.front;
      back.textContent = currentCard.back;

      currentCard.isNew
         ? cardEl.classList.add('isNew')
         : cardEl.classList.remove('isNew');

      this.#adjustNextReviewTime();
   }

   // Adjusts the easy and hard text to show when the next time the card will need to be reviewed
   #adjustNextReviewTime() {
      const hardBtn = document.querySelector('.button-answer-container .hard');
      const easyBtn = document.querySelector('.button-answer-container .easy');

      const hardTime = hardBtn.querySelector('span');
      const easyTime = easyBtn.querySelector('span');

      // HERE TO DO

      const currentCardMaturity = this.#cardsToStudy[0].maturity;

      if (currentCardMaturity === 0) {
         hardTime.textContent = '<1m';
         easyTime.textContent = '10m';
         easyBtn.setAttribute('data-points', 5);
      }

      if (currentCardMaturity >= 1) {
         hardTime.textContent = '10m';
         easyTime.textContent = `${currentCardMaturity}d`;
         easyBtn.setAttribute('data-points', (currentCardMaturity + 1) * 2 + 1);
      }

      easyBtn.setAttribute('data-maturity', currentCardMaturity);
   }

   // When either showAnswer, easy, or hard is clicked
   answerBoxListeners(cardIncorrect, cardCorrect, grabMoreCards) {
      const cardEl = document.querySelector('section.middle .card-page .card');
      const backOfCard = cardEl.querySelector('.back');
      const answerBox = document.querySelector('.button-answer-container');
      const showAnswerBtn = answerBox.querySelector('.show-answer');
      const hardBtn = answerBox.querySelector('.hard');
      const easyBtn = answerBox.querySelector('.easy');
      const rightWrongWidget = cardEl.querySelector('.right-wrong-widget');

      answerBox.addEventListener('click', async e => {
         const clickedBtn = e.target.closest('button');

         if (!clickedBtn) return;

         // if the showAnsweBtn is clicked, we hide that button and show the easy and hard buttons + show the back of the card
         if (clickedBtn === showAnswerBtn) {
            hardBtn.classList.remove('hidden');
            easyBtn.classList.remove('hidden');
            showAnswerBtn.classList.add('hidden');
            backOfCard.classList.add('active');

            return;
         }

         // If hard is clicked
         if (clickedBtn === hardBtn) {
            rightWrongWidget.innerHTML = `<p>❌</p>`;
            cardIncorrect(this.#cardId);
         }

         // If easy is clicked
         if (clickedBtn === easyBtn) {
            const xpEarned = easyBtn.dataset.points;
            const experienceEarned = document.querySelector(
               'section.middle .card-page .top .experience span'
            );
            const currentPoints = experienceEarned.textContent;

            experienceEarned.textContent = +xpEarned + +currentPoints;

            rightWrongWidget.innerHTML = `<p>✅</p>`;
            cardCorrect(this.#cardId);
         }

         rightWrongWidget.classList.remove('hidden');
         await waitInSeconds(0.3);
         rightWrongWidget.classList.add('hidden');

         // If hard or easy is clicked we then want to show the showAnswer button again and remove the easy hard buttons
         hardBtn.classList.add('hidden');
         easyBtn.classList.add('hidden');
         showAnswerBtn.classList.remove('hidden');
         backOfCard.classList.remove('active');

         // Remove the 1st element (current card) from the cardsToStudy array
         this.#cardsToStudy.shift();

         console.log(this.#cardsToStudy);

         // If we're at 0 cards, we then grab more cards
         if (this.#cardsToStudy.length === 0) return grabMoreCards(true);

         this.#adjustStats(clickedBtn);

         // Then display the next card
         this.#adjustCardHtml();
      });
   }

   #adjustStats(clickedBtn) {
      const cardEl = document.querySelector('section.middle .card-page .card');
      const reviewCardsEl = document.querySelector(
         'section.middle .card-page li h5.review-cards span'
      );
      const reviewNumber = +reviewCardsEl.textContent;

      const newCardsEl = document.querySelector(
         'section.middle .card-page li h5.new-cards span'
      );
      const newCardsNumber = +newCardsEl.textContent;

      // If the card is new, we subtract 1 from new cards and add 1 to review cards
      if (cardEl.classList.contains('isNew')) {
         reviewCardsEl.textContent = reviewNumber + 1;
         newCardsEl.textContent = newCardsNumber - 1;
         return;
      }

      // If the clicked button is "hard", we don't do anything
      if (clickedBtn.classList.contains('hard')) {
         return;
      }

      // If the clicked button is easy
      // If the current maturity is at 0, we don't do anything since we'll see it again in 10 minutes
      // But if the maturity is not 0, then we know we will see in 1+ days, so we subtract from cards to review
      if (clickedBtn.classList.contains('easy')) {
         const { maturity } = clickedBtn.dataset;

         if (+maturity === 0) return;
         else reviewCardsEl.textContent = reviewNumber - 1;
      }
   }

   noMoreCards(showPopup) {
      const cardEl = document.querySelector('section.middle .card-page .card');
      const buttonAnswerContainer = document.querySelector(
         'section.middle .card-page .button-answer-container'
      );
      buttonAnswerContainer.style.display = 'none';
      cardEl.style.justifyContent = 'center';
      cardEl.innerHTML = `<h4>You have no more cards to review!</h4>
                          <p>Go back and study other decks or study ahead</p>
                          <div class="no-cards-button-container">
                              <a class="btn secondary go-back">Go back</a>
                              <button class="btn primary study-ahead">Study ahead</button>
                          </div>
      `;

      const goBack = cardEl.querySelector('a.go-back');
      const studyAhead = cardEl.querySelector('button.study-ahead');

      goBack.setAttribute('href', `./app.html#home`);

      studyAhead.addEventListener('click', () => {
         showPopup('Study ahead', this.#studyAheadForm);
      });
   }

   studyAheadListener(handler) {
      document.querySelector('.start-studying').addEventListener('click', e => {
         e.preventDefault();
         handler(e, this.#studyAheadForm, this.#studyAheadFormInputs);
      });
   }

   #pageLayoutStudyMode() {
      const main = document.querySelector('main');
      const sectionLeft = document.querySelector('section.left');
      const sectionRight = document.querySelector('section.right');

      sectionLeft.style.display = 'none';
      sectionRight.style.display = 'none';
      main.style.gridTemplateColumns = '1fr';
   }

   maxHeightForDeck() {
      // const card = document.querySelector('section.middle .card-page .card');
      // console.log(card);
      // if (card) {
      //    const computedHeight = window.getComputedStyle(card).height;
      //    card.style.maxHeight = computedHeight;
      // }
   }

   get #cardBaseHtml() {
      return `
      <div class="card-page">
         <div class="top-card-page">
            <a href="/app.html#home" class="back"><svg fill="#000000" width="256px" height="256px" viewBox="0 0 24.00 24.00" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M1.293,12.707a1,1,0,0,1,0-1.414l6-6A1,1,0,0,1,8.707,6.707L4.414,11H22a1,1,0,0,1,0,2H4.414l4.293,4.293a1,1,0,0,1-1.414,1.414Z"></path></g></svg></a>
           <!-- <h4 class="name-deck">Trivia Facts</h4> -->
            <ul class="top">
               <li>
                  <h5 class="stat bold review-cards">
                 <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V12.5L14 14.5M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z" stroke="#000000" stroke-width="1.32" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span><span class="skeleton skeleton-container"></span></span></h5>
                  <p class="subtext">Cards to review</p>
               </li>
               <li>
                  <h5 class="stat bold new-cards">
                  <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-2.56 -2.56 37.12 37.12" xml:space="preserve" fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <style type="text/css"> 
                    .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;} 
                  </style> 
                  <path class="st0" d="M18,29H8c-2.2,0-4-1.8-4-4V7c0-2.2,1.8-4,4-4h10c2.2,0,4,1.8,4,4v18C22,27.2,20.2,29,18,29z"></path> 
                  <path class="st0" d="M22,6.4l5,3c1.9,1.1,2.5,3.6,1.3,5.5L22,25.5"></path> 
                  <path class="st0" d="M28,16l0.5,0.7c1.3,1.8,0.8,4.3-1,5.6l-5.7,4"></path>
                  <!-- Plus sign -->
                  <line x1="13" y1="12" x2="13" y2="18" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <line x1="10" y1="15" x2="16" y2="15" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>
                  <span><span class="skeleton skeleton-container"></span></span></h5>
                  <p class="subtext">New cards</p>
               </li>
               <li>
                  <h5 class="stat bold experience">
                     💎 <span class=><span class="skeleton skeleton-container"></span></span></h5>
                     <p class="subtext">Deck XP</p>
               </li>
            </ul>
         </div>

         <div class="card skeleton-container">
            <p class="front"><span class="skeleton sub"></span></p>
            <p class="back active"><span class="skeleton"></span><span class="skeleton"></span><span class="skeleton sub"></span></p>
            <div class="right-wrong-widget hidden"><p>+5 points</p></div>
         </div>

         <div class="button-answer-container">
            <button class="btn rainbow-button show-answer">Show Answer</button>
            <button class="btn primary hard hidden">Hard <span>> 1m</span></button>
            <button class="btn primary easy hidden">Easy <span>10 d</span></button>
         </div>
      </div>
      `;
   }
}

export default new CardView();
