import HomeModel from '../model/HomeModel';
import HomeView from '../view/HomeView';
import { showMessage } from '../../helpers/messages';

class HomeController {
   async init() {
      await this.#renderHomeHtml();

      HomeView.searchEventListener();
      HomeView.deckContainerClicked(this.#createDeck);
   }

   async #createDeck(e) {
      e.target.disabled = true;

      try {
         const newDeck = await HomeModel.createDeck();

         if (newDeck.statusCode !== 201) throw new Error(newDeck.message);

         window.location.assign(`#decks/${newDeck.data.data._id}`);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }

      e.target.disabled = false;
   }

   async #renderHomeHtml() {
      HomeView.renderHomeBaseHtml();

      try {
         const decks = await HomeModel.loadDecks();

         console.log(decks);

         if (decks.statusCode !== 200) throw new Error(decks.message);

         HomeView.renderDecks(decks.data.data);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }

   // searchTimer;

   // async #searchDecks(e) {
   //    const value = e.target.value;

   //    clearTimeout(this.searchTimer);

   //    this.searchTimer = setTimeout(async () => {
   //       const decks = await HomeModel.searchDecks(value);

   //       HomeView.renderDecks(decks.data.data);
   //    }, 200);
   // }
}

export default new HomeController();
