import LeaderboardModel from '../model/LeaderboardModel';
import LeaderboardView from '../view/LeaderboardView';
import { showMessage } from './../../helpers/messages';

class LeaderboardController {
   init() {
      LeaderboardView.renderLeaderboardBaseHtml();

      this.#adjustLeaderboard('daily');

      this.#registerEventListeners();
   }

   #registerEventListeners() {
      LeaderboardView.tableButtonClickListener(this.#adjustLeaderboard);
   }

   async #adjustLeaderboard(filter) {
      try {
         const results = await LeaderboardModel.filterLeaderboard(filter);

         if (results.statusCode !== 200) throw new Error(results.message);

         // If this is the case, then the user isn't following anyone!
         if (results.data.allUsers.length === 1)
            return LeaderboardView.noFollowers();

         const orderedResults = results.data.allUsers.sort(
            (a, b) => b.experience - a.experience
         );

         LeaderboardView.adjustTableHtml(orderedResults);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }
}

export default new LeaderboardController();
