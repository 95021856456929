import { getJSON, postJSON } from './../../helpers/requests';
import { API_URL } from '../../config';

class FriendsModel {
   async getUsers(searchVal) {
      const userSearchUrl = `${API_URL}/users?search=${searchVal}`;
      const res = await getJSON(userSearchUrl);
      return res;
   }

   async followUnfollow(id, type) {
      const followUnfollowUrl = `${API_URL}/socials/${id}/${type}`;
      const res = await postJSON(followUnfollowUrl);
      return res;
   }
}

export default new FriendsModel();
