import { API_URL } from '../../config';
import { getJSON, postJSON } from '../../helpers/requests';

class AppModel {
   async logUserOut() {
      const logoutURL = `${API_URL}/auth/log-out`;

      const res = await postJSON(logoutURL);

      return res;
   }

   async createDeck() {
      const createDeckUrl = `${API_URL}/decks`;

      const postDeck = await postJSON(createDeckUrl, {
         name: 'Untitled Deck',
         description: 'This is my super cool description!',
      });

      return postDeck;
   }

   async loadWeeklyVersus(id) {
      const getWeeklyVersusUrl = `${API_URL}/activity/versus/${id}`;
      const data = await getJSON(getWeeklyVersusUrl);
      return data;
   }

   async getFriends() {
      const getFriendsUrl = `${API_URL}/socials`;
      const data = await getJSON(getFriendsUrl);
      return data;
   }

   removeUserLocalStorage() {
      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
      localStorage.removeItem('email');
      localStorage.removeItem('imageUrl');
      localStorage.removeItem('experience');
      localStorage.removeItem('cardsStudied');
      localStorage.removeItem('streak');
      localStorage.removeItem('id');
      localStorage.removeItem('createdVia');
   }
}

export default new AppModel();
