import ProfileModel from '../model/ProfileModel';
import ProfileView from '../view/ProfileView';
import {
   grabFormValues,
   checkFormForErrors,
   clearFormInputs,
} from '../../helpers/form';
import { renderSpinner } from '../../helpers/renderSpinner';
import { showMessage } from '../../helpers/messages';
import waitInSeconds from '../../helpers/waitInSeconds';
import updateUserLocalStorage from '../../helpers/updateUserLocalStorage';
import AppView from '../view/AppView';
import cloudinarySigned from '../../helpers/cloudinarySigned';

class ProfileController {
   init() {
      ProfileView.renderProfileHtml();

      this.#registerEventListeners();
   }

   #registerEventListeners() {
      ProfileView.saveProfileListener(this.#saveProfile);
      ProfileView.imageChangeEventListener(this.#imageUploaded);
      ProfileView.changePasswordListener(this.#changePassword);
   }

   async #imageUploaded(file) {
      try {
         // Get signed URL
         const signed = await cloudinarySigned();

         if (signed.statusCode !== 200) throw new Error(signed.message);

         const data = new FormData();
         data.append('file', file);
         data.append('api_key', process.env.CLOUDINARY_API_KEY);
         data.append('signature', signed.data.signature);
         data.append('timestamp', signed.data.timestamp);
         data.append('folder', 'Profile Images');

         // Post the image to cloudinary
         const cloudinaryPost = await ProfileModel.cloudinaryPost(data);

         if (cloudinaryPost.error)
            throw new Error('There was a problem uploading the image.');

         // Save the image to the database
         const saveUserImage = await ProfileModel.saveImageUrl(
            cloudinaryPost.secure_url
         );

         if (saveUserImage.statusCode !== 200)
            throw new Error(saveUserImage.message);

         showMessage(
            'positive',
            'You have successfully update your profile image.'
         );
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }

   async #changePassword(e, changePassForm, changePassFormInputs) {
      const errorsFound = checkFormForErrors(changePassFormInputs);

      if (errorsFound) return;

      const formValues = grabFormValues(changePassForm);

      renderSpinner(e.target, 'white');

      try {
         const changePassword = await ProfileModel.changeUserPassword(
            formValues
         );

         if (changePassword.statusCode !== 200)
            throw new Error(changePassword.message);

         await waitInSeconds(1);

         showMessage(
            'positive',
            'You have successfully changed your password.'
         );

         clearFormInputs(changePassForm);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }

      e.target.textContent = 'Save password';
   }

   async #saveProfile(e, saveForm, saveFormInputs) {
      const errorsFound = checkFormForErrors(saveFormInputs);

      if (errorsFound) return;

      const formData = new FormData(saveForm);
      const data = Object.fromEntries(formData);

      renderSpinner(e.target, 'white');

      console.log(data);

      try {
         const userData = await ProfileModel.updateUserData(data);

         if (userData.statusCode !== 200) throw new Error(userData.message);

         // When user saves their profile, we update localStorage and then reload the right side of the dashboard (fresh data)
         updateUserLocalStorage(userData);
         AppView.loadRightSideOfDashboard();

         await waitInSeconds(1);

         showMessage('positive', 'You have successfully updated your profile');
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }

      e.target.textContent = 'Save changes';
   }
}

export default new ProfileController();
