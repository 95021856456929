import { API_URL } from './../../config';
import { getJSON } from './../../helpers/requests';
class LeaderboardModel {
   async filterLeaderboard(filter) {
      const leaderboardUrl = `${API_URL}/activity/${filter}`;
      const res = await getJSON(leaderboardUrl);
      return res;
   }
}

export default new LeaderboardModel();
