import { API_URL } from '../../config';
import {
   getJSON,
   patchJSON,
   deleteJSON,
   postJSON,
   postJSONMultiform,
} from '../../helpers/requests';

class DeckModel {
   async loadADeck(id) {
      const getADeckUrl = `${API_URL}/decks/${id}`;
      const deck = await getJSON(getADeckUrl);
      return deck;
   }

   async editDeck(id, data) {
      const patchDeckUrl = `${API_URL}/decks/${id}`;
      const updateDeck = await patchJSON(patchDeckUrl, data);
      return updateDeck;
   }

   async deleteDeck(id) {
      const deleteDeckUrl = `${API_URL}/decks/${id}`;
      const deleteData = await deleteJSON(deleteDeckUrl);
      return deleteData;
   }

   // TO DO --> transcript disabled https://www.youtube.com/watch?v=89CI8bOsdXQ

   async createCards(id, type, data) {
      const createCardYoutubeUrl = `${API_URL}/decks/${id}/cards-generate/${type}`;

      let createCards;

      if (type === 'pdf')
         createCards = await postJSONMultiform(createCardYoutubeUrl, data);
      else createCards = await postJSON(createCardYoutubeUrl, data);

      return createCards;
   }

   async createFromManual(id, data) {
      const createCardManualUrl = `${API_URL}/decks/${id}/cards`;
      const createCard = await postJSON(createCardManualUrl, data);
      return createCard;
   }

   async editCard(id, data) {
      const editCardUrl = `${API_URL}/cards/${id}`;
      const editCard = await patchJSON(editCardUrl, data);
      return editCard;
   }

   async deleteCard(id) {
      const deleteCardUrl = `${API_URL}/cards/${id}`;
      const deleteCard = await deleteJSON(deleteCardUrl);
      return deleteCard;
   }
}

export default new DeckModel();
