import { ROOT_URL, ROOT_URL_FRONTEND } from '../../config';
import timestampToHumanDate from '../../helpers/timestampToDate';
import { showMessage } from './../../helpers/messages';

class FriendsView {
   #searchTimer;

   renderFriendsBaseHtml() {
      const middleContent = document.querySelector('section.middle');

      middleContent.innerHTML = this.#middleFriendsHtml;
   }

   searchListener(handler) {
      const search = document.querySelector('section.middle .friends input');

      search.addEventListener('input', e => {
         const searchVal = e.target.value;

         clearTimeout(this.#searchTimer);

         if (!searchVal || searchVal.trim() === '') return;

         this.#searchTimer = setTimeout(() => handler(searchVal), 650);
      });
   }

   inviteLinkListener() {
      const inviteLink = document.querySelector(
         'section.middle .friends button'
      );

      const userId = window.localStorage.getItem('id');

      inviteLink.setAttribute(
         'data-copy',
         `Join me on Kiku! ${ROOT_URL_FRONTEND}/app.html#user/${userId}?follow=true`
      );

      inviteLink.addEventListener('click', e => {
         const button = e.target.closest('button');

         if (!button) return;

         this.#copyInviteLink();
      });
   }

   #copyInviteLink() {
      const button = document.querySelector(
         'section.middle .friends button.invite-link'
      );
      const copyText = button.dataset.copy;

      showMessage('positive', 'Your invite link has been copied!');

      navigator.clipboard.writeText(copyText);
   }

   loadUsers(users, isFollowingIds, value) {
      const userList = document.querySelector(
         'section.middle .friends ul.user-list'
      );

      userList.innerHTML = '';

      users.forEach(user => {
         const html = this.#generateUserHtml(user, isFollowingIds);
         userList.insertAdjacentHTML('beforeend', html);
      });

      if (users.length === 0) {
         this.#copyInviteLink();
         userList.innerHTML = `<h5>No results for: ${value}.</h5> 
                               <p>They might not have signed up yet. Share the copied invite link with them!</p>`;
      }

      userList.style.display = 'grid';
   }

   #generateUserHtml(user, isFollowingIds) {
      const { id, firstName, lastName, imageUrl } = user;

      const isFollowing = isFollowingIds.includes(id);

      return `<a href="#user/${id}">
                  <li>
                     <div class="left">
                        <img alt="Follower Image" src="${imageUrl}">
                        <p>${firstName} ${lastName}</p>
                     </div>
                     
                     <!-- If the user profile is following the user viewing, then we don't show the button - else we check if the user profile is following the currUser -->
                     <button data-id="${id}" class="btn secondary ${
         isFollowing ? 'unfollow' : 'follow'
      }"> ${isFollowing ? 'Unfollow' : 'Follow'}</button>
                     
                  </li>
               </a>`;
   }

   userClickListener(handler) {
      const userList = document.querySelector(
         'section.middle .friends ul.user-list'
      );

      userList.addEventListener('click', e => {
         const button = e.target.closest('button');

         if (!button) return;

         if (button) e.preventDefault();

         const { id } = button.dataset;

         if (button.classList.contains('follow')) {
            handler(id, 'follow');
            button.textContent = 'Unfollow';
            button.classList.remove('follow');
            button.classList.add('unfollow');
            return;
         }

         if (button.classList.contains('unfollow')) {
            handler(id, 'unfollow');
            button.textContent = 'Follow';
            button.classList.remove('unfollow');
            button.classList.add('follow');
            return;
         }
      });
   }

   get #middleFriendsHtml() {
      return `
   <div class="friends">
      <div class="top">
         <h3>Find friends</h3>
      </div>

        <div class="input-with-icon">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z" fill="#000"></path> </g></svg>
            <input type="text" placeholder="Search for friends (email or name)">
        </div>

        <ul class="user-list">
            
        </ul>

        <div class="invite-container">
            <h5>Or send them a link</h5>
            <button class="btn secondary invite-link">
            <svg viewBox="-2.16 -2.16 28.32 28.32" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15.197 3.35462C16.8703 1.67483 19.4476 1.53865 20.9536 3.05046C22.4596 4.56228 22.3239 7.14956 20.6506 8.82935L18.2268 11.2626M10.0464 14C8.54044 12.4882 8.67609 9.90087 10.3494 8.22108L12.5 6.06212" stroke="#000" stroke-width="1.5" stroke-linecap="round"></path> <path d="M13.9536 10C15.4596 11.5118 15.3239 14.0991 13.6506 15.7789L11.2268 18.2121L8.80299 20.6454C7.12969 22.3252 4.55237 22.4613 3.0464 20.9495C1.54043 19.4377 1.67609 16.8504 3.34939 15.1706L5.77323 12.7373" stroke="#000" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
            Copy invite link</button>
        </div>
   </div>
`;
   }
}

export default new FriendsView();
