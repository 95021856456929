const adjustMainLayout = () => {
   const main = document.querySelector('main');

   main.querySelector('section.left').style.display = 'flex';
   main.querySelector('section.right').style.display = 'flex';

   const root = getComputedStyle(document.querySelector(':root'));
   const mainLayout = root.getPropertyValue('--main-app-layout');

   main.style.gridTemplateColumns = mainLayout;

   // Middle

   const middle = document.querySelector('section.middle');

   const marginBottom = root.getPropertyValue('--section-middle-margin-bottom');
   const padding = root.getPropertyValue('--section-padding');

   console.log(padding);

   middle.style.marginBottom = marginBottom;
   middle.style.padding = padding;
};

export default adjustMainLayout;
