class HomeView {
   renderHomeBaseHtml() {
      const middleContent = document.querySelector('section.middle');

      middleContent.innerHTML = this.#middleHomeHtml;
   }

   #showStatCount(decks) {
      const totalCardCountEl = document.querySelector(
         'section.middle .homepage .total-cards span'
      );
      const reviewCardCountEl = document.querySelector(
         'section.middle .homepage .review-cards span'
      );
      const newCardCountEl = document.querySelector(
         'section.middle .homepage .new-cards span'
      );

      let totalCardCount = 0;
      let dueCardCount = 0;
      let newCardCount = 0;
      decks.forEach(deck => {
         const { totalCards } = deck;
         const { dueCards } = deck;
         const { newCards } = deck;
         const { urgentlyDueCards } = deck;

         totalCardCount += totalCards.results;
         dueCardCount += dueCards.results + urgentlyDueCards.results;
         newCardCount += newCards.results;
      });

      totalCardCountEl.textContent = totalCardCount;
      reviewCardCountEl.textContent = dueCardCount;
      newCardCountEl.textContent = newCardCount;
   }

   renderDecks(decks) {
      this.#showStatCount(decks);

      const card = document.querySelector('.middle .card');
      const decksList = card.querySelector('ul');

      decksList.innerHTML = '';

      // If no decks, show a button to create the first deck
      if (decks.length === 0) {
         card.innerHTML =
            '<button class="primary rainbow-button create-deck">Create deck</button>';

         return;
      }

      const sortedDeckByReviewCards = decks.sort((a, b) => {
         const aDueCards = a.dueCards.results + a.urgentlyDueCards.results;
         const bDueCards = b.dueCards.results + b.urgentlyDueCards.results;

         return bDueCards - aDueCards;
      });

      // Show the list of decks
      sortedDeckByReviewCards.forEach(deck => {
         const html = this.#generateDeckHtml(deck);
         decksList.insertAdjacentHTML('beforeend', html);
      });
   }

   deckContainerClicked(createDeck) {
      const deckContainer = document.querySelector('section.middle .card');

      deckContainer.addEventListener('click', e => {
         const btn = e.target.closest('button');

         if (!btn) return;

         if (btn.classList.contains('create-deck')) createDeck(e);
      });
   }

   #generateDeckHtml(deck) {
      const cardsDue = deck.dueCards.results + deck.urgentlyDueCards.results;

      console.log(deck);

      return `
      <li>
      <a style="border-left: 10px solid ${deck.color}" href="./app.html#decks/${
         deck.id
      }"> 
                    <div class="top">
                        <h5>${deck.name}</h5>
                        <p class="description">${deck.description}</p>
                    </div>
                    <div class="deck-stats">
                        <div class="widget card-count">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V12.5L14 14.5M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z" stroke="#000000" stroke-width="1.32" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        <p class="subtext">${cardsDue} ${
         cardsDue === 1 ? 'card' : 'cards'
      } due</p></div>
                        <div class="subtext widget total-card-count">  
                        <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-2.56 -2.56 37.12 37.12" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <style type="text/css"> .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;} </style> <path class="st0" d="M18,29H8c-2.2,0-4-1.8-4-4V7c0-2.2,1.8-4,4-4h10c2.2,0,4,1.8,4,4v18C22,27.2,20.2,29,18,29z"></path> <path class="st0" d="M22,6.4l5,3c1.9,1.1,2.5,3.6,1.3,5.5L22,25.5"></path> <path class="st0" d="M28,16l0.5,0.7c1.3,1.8,0.8,4.3-1,5.6l-5.7,4"></path> </g></svg>
                        <p class="subtext">${deck.totalCards.results} ${
         deck.totalCards.results === 1 ? 'card' : 'cards'
      }</p></div>
                    </div>
                </a>
            </li>`;
   }

   // <p> ${deck.totalCards} total cards </p>
   // <p> ${deck.numCardsDue} cards due today </p>
   // <p> ${deck.numCardsDueInFuture} cards due in future </p>
   // <p> ${deck.numNewCards} cards yet to be reviewed (new cards) </p>

   searchEventListener() {
      const allDecks = document.querySelectorAll(
         'section.middle .homepage ul.deck-list li'
      );

      document
         .querySelector('section.middle .homepage input')
         .addEventListener('input', e => {
            const value = e.target.value.toLowerCase();

            let count = 0;
            allDecks.forEach(deck => {
               const titleEl = deck.querySelector('h5');
               const titleText = titleEl.textContent.toLowerCase();
               const descriptionEl = deck.querySelector('p');
               const descriptionText = descriptionEl.textContent.toLowerCase();

               if (
                  !titleText.includes(value) &&
                  !descriptionText.includes(value)
               )
                  return (deck.style.display = 'none');

               deck.style.display = 'flex';
               count++;

               this.#highlightText(titleEl, value);
               this.#highlightText(descriptionEl, value);
            });
         });
   }

   #highlightText(element, text) {
      const textContent = element.textContent;

      const updatedText = textContent.replaceAll(
         new RegExp(text, 'gi'),
         match => `<span class="searched-for">${match}</span>`
      );

      element.innerHTML = updatedText;
   }

   get #middleHomeHtml() {
      const name = localStorage.getItem('firstName');
      return `
   <div class="homepage">
      <div class="top">
         <h3>Hi <span>${name}</span>!</h3>
         <p class="subtext quote">"The journey of a thousand miles begins with a single step."</p>
      </div>

      <ul class="top">
         <li>
            <h4 class="stat review-cards">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V12.5L14 14.5M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z" stroke="#000000" stroke-width="1.32" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            <span><span class="skeleton-container skeleton"></span></span></h4>
            <p class="subtext">Cards to review</p>
            </li>
         <li>
          <h4 class="stat total-cards">
      <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-2.56 -2.56 37.12 37.12" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <style type="text/css"> .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;} </style> <path class="st0" d="M18,29H8c-2.2,0-4-1.8-4-4V7c0-2.2,1.8-4,4-4h10c2.2,0,4,1.8,4,4v18C22,27.2,20.2,29,18,29z"></path> <path class="st0" d="M22,6.4l5,3c1.9,1.1,2.5,3.6,1.3,5.5L22,25.5"></path> <path class="st0" d="M28,16l0.5,0.7c1.3,1.8,0.8,4.3-1,5.6l-5.7,4"></path> </g></svg>
          <span><span class="skeleton-container skeleton"></span></span></h4>
            <p class="subtext">Total cards</p>
            </li>
         <li>
            <h4 class="stat new-cards">
                            <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-2.56 -2.56 37.12 37.12" xml:space="preserve" fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <style type="text/css"> 
                    .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;} 
                  </style> 
                  <path class="st0" d="M18,29H8c-2.2,0-4-1.8-4-4V7c0-2.2,1.8-4,4-4h10c2.2,0,4,1.8,4,4v18C22,27.2,20.2,29,18,29z"></path> 
                  <path class="st0" d="M22,6.4l5,3c1.9,1.1,2.5,3.6,1.3,5.5L22,25.5"></path> 
                  <path class="st0" d="M28,16l0.5,0.7c1.3,1.8,0.8,4.3-1,5.6l-5.7,4"></path>
                  <!-- Plus sign -->
                  <line x1="13" y1="12" x2="13" y2="18" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <line x1="10" y1="15" x2="16" y2="15" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>
            <span><span class="skeleton-container skeleton"></span></span></h4>
            <p class="subtext">New cards</p>
            </li>
      </ul>

      <div class="input-with-icon">
         <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z" fill="#000"></path> </g></svg>
         <input type="text" placeholder="Search your decks">
      </div>

      <div class="card">
         <div class="top-of-card">
            <h4>Decks</h4>
            <button class="create-deck btn secondary">
            <svg width="237px" height="237px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 19H5C3.89543 19 3 18.1046 3 17V7C3 5.89543 3.89543 5 5 5H9.58579C9.851 5 10.1054 5.10536 10.2929 5.29289L12 7H19C20.1046 7 21 7.89543 21 9V11" stroke="#000000" stroke-width="1.536" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M18 14V17M18 20V17M18 17H15M18 17H21" stroke="#000000" stroke-width="1.536" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            Create deck</button>
         </div>
         <ul class="deck-list">

            <li class="skeleton-container">
               <a> 
                <div class="deck-info">
                    <div class="top">
                        <h5><span class="skeleton"></span></h5>
                        <p class="description"><span class="skeleton"></span><span class="skeleton sub"></span></p>
                    </div>
                    <div class="deck-stats">
                        <div class="widget card-count">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V12.5L14 14.5M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z" stroke="#000000" stroke-width="1.32" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        <p class="subtext"><span class="skeleton"></span></p></div>
                        <div class="subtext widget total-card-count">  
                        <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-2.56 -2.56 37.12 37.12" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <style type="text/css"> .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;} </style> <path class="st0" d="M18,29H8c-2.2,0-4-1.8-4-4V7c0-2.2,1.8-4,4-4h10c2.2,0,4,1.8,4,4v18C22,27.2,20.2,29,18,29z"></path> <path class="st0" d="M22,6.4l5,3c1.9,1.1,2.5,3.6,1.3,5.5L22,25.5"></path> <path class="st0" d="M28,16l0.5,0.7c1.3,1.8,0.8,4.3-1,5.6l-5.7,4"></path> </g></svg>
                        <p class="subtext"><span class="skeleton"></span></p></div>
                    </div>
                </div>
                </a>
            </li>

                        <li class="skeleton-container">
               <a> 
                <div class="deck-info">
                    <div class="top">
                        <h5><span class="skeleton"></span></h5>
                        <p class="description"><span class="skeleton"></span><span class="skeleton sub"></span></p>
                    </div>
                    <div class="deck-stats">
                        <div class="widget card-count">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V12.5L14 14.5M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z" stroke="#000000" stroke-width="1.32" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        <p class="subtext"><span class="skeleton"></span></p></div>
                        <div class="subtext widget total-card-count">  
                        <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-2.56 -2.56 37.12 37.12" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <style type="text/css"> .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;} </style> <path class="st0" d="M18,29H8c-2.2,0-4-1.8-4-4V7c0-2.2,1.8-4,4-4h10c2.2,0,4,1.8,4,4v18C22,27.2,20.2,29,18,29z"></path> <path class="st0" d="M22,6.4l5,3c1.9,1.1,2.5,3.6,1.3,5.5L22,25.5"></path> <path class="st0" d="M28,16l0.5,0.7c1.3,1.8,0.8,4.3-1,5.6l-5.7,4"></path> </g></svg>
                        <p class="subtext"><span class="skeleton"></span></p></div>
                    </div>
                </div>
                </a>
            </li>

         </ul>
      </div>
   </div>
`;
   }
}

{
   /* <section class="right">
    <!-- TOP -->

    <div class="top sub-section">
        <h3>
            <i class="fa-solid fa-fire-flame-curved"></i>
            Streak
        </h3>
        <div class="card">
            <p><span class="streak-count">12</span> days in a row</p>
        </div>
    </div>

    <!-- BOTTOM -->
    <!-- <div class="bottom sub-section">
        <h3>Activity</h3>
    </div> -->
    </section> */
}

export default new HomeView();
