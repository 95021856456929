import UserModel from '../model/UserModel';
import UserView from '../view/UserView';
import { showMessage } from '../../helpers/messages';
import PopupController from '../../shared/controller/PopupController';
import { renderSpinner } from './../../helpers/renderSpinner';

class UserController {
   #eventListenersRegistered = false;

   async init() {
      UserView.renderUserBaseHtml();

      await this.#renderUserProfile();

      this.#registerEventListeners();

      PopupController.close();

      this.#lookAtSearchParams();

      this.#checkParmasForSharedDeck();
   }

   #checkParmasForSharedDeck() {
      const [_, queryStr] = window.location.hash.split('?');
      if (!queryStr) return;

      const params = new URLSearchParams(queryStr);

      const sharedDeckId = params.get('sharedDeck');

      if (!sharedDeckId) return;

      UserView.userIsDownloadingSharedDeck(sharedDeckId);
   }

   #lookAtSearchParams() {
      // Goes from /app.html#user/432432432?follow=true to ---> [/app.html#user, 432343242323/follow=true]
      const [_, endingOfHash] = window.location.hash.split('/');

      // Goes from 432343242323/follow=true to ---> [43242143143, follow=true]
      const [userId, queryString] = endingOfHash.split('?');

      // Makes it easy to get all of the search params
      const searchParams = new URLSearchParams(queryString);
      const autoFollow = searchParams.get('follow');

      if (autoFollow) this.#followUnfollow('follow', userId);

      this.#loadGraph(userId);
   }

   async #loadGraph(userId) {
      const currUserId = window.localStorage.getItem('id');
      // Don't generate the graph if the logged in user is the same as the profile user (what's on screen)
      if (userId === currUserId) return;

      try {
         const data = await UserModel.loadWeeklyVersus(userId);

         console.log(data, userId);

         UserView.loadGraph(data);
      } catch (err) {
         console.log(err);
         showMessage('negative', err.message);
      }
   }

   async #renderUserProfile() {
      const [_, userId] = window.location.hash.split('/');

      try {
         const user = await UserModel.getUser(userId);

         if (user.statusCode !== 200) throw new Error(user.message);

         console.log(user);
         UserView.renderUserDetails(
            user.data.userProfile,
            user.data.currentUserProfile
         );
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }

   async #deckIsDownloaded(e, id) {
      renderSpinner(e.target, 'white');

      try {
         const downloadedDeck = await UserModel.downloadDeck(id);

         console.log(downloadedDeck);

         if (downloadedDeck.statusCode !== 200)
            throw new Error(downloadedDeck.message);

         showMessage(
            'positive',
            `${downloadedDeck.data.deck.name} has been successfully added to your account!`
         );

         console.log(downloadedDeck);

         PopupController.close();
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }

      e.target.textContent = 'Download deck';
   }

   #registerEventListeners() {
      UserView.deckListClickListener(this.#deckClicked);
      UserView.followersFollowingListener(this.#followersFollowingClicked);
      UserView.followUnfollowListener(this.#followUnfollow);

      // Everything below is NOT rerendered when navigating, so we don't want to register event listeners again
      if (this.#eventListenersRegistered) return;

      UserView.deckDownloadListener(this.#deckIsDownloaded);
      UserView.followUnfollowPopupListener(this.#followUnfollow);

      this.#eventListenersRegistered = true;
   }

   async #followUnfollow(type, id) {
      try {
         const social = await UserModel.followUnfollow(type, id);

         if (social.statusCode !== 200) throw new Error(social.message);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }

   #deckClicked(title, popupContent) {
      PopupController.show(title, popupContent);
   }

   #followersFollowingClicked(title, popupContent) {
      PopupController.show(title, popupContent);
   }
}

export default new UserController();
