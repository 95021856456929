import { API_URL } from '../../config';
import { patchJSON } from '../../helpers/requests';

class ProfileModel {
   async updateUserData(data) {
      const updateUserUrl = `${API_URL}/users/me`;
      const res = await patchJSON(updateUserUrl, data);
      return res;
   }

   async changeUserPassword(data) {
      const changePasswordUrl = `${API_URL}/users/change-password`;
      const res = await patchJSON(changePasswordUrl, data);
      return res;
   }

   async cloudinaryPost(data) {
      const uploadImageUrl = `https://api.cloudinary.com/v1_1/${process.env.CLOUDINARY_CLOUDNAME}/image/upload`;

      const postToCloudinary = await fetch(uploadImageUrl, {
         method: 'POST',
         body: data,
         credentials: 'omit',
      });

      const res = await postToCloudinary.json();

      return res;
   }

   async saveImageUrl(secureUrl) {
      const imageUrl = secureUrl;

      const saveUserImage = `${API_URL}/users/me`;
      const res = await patchJSON(saveUserImage, { imageUrl });
      return res;
   }
}

export default new ProfileModel();
