import { sendMessageToNextPage, showMessage } from '../../helpers/messages';
import DeckModel from '../model/DeckModel';
import DeckView from '../view/DeckView';
import PopupController from '../../shared/controller/PopupController';
import { renderSpinner } from '../../helpers/renderSpinner';
import {
   grabFormValues,
   checkFormForErrors,
   clearFormInputs,
} from '../../helpers/form';
import waitInSeconds from '../../helpers/waitInSeconds';
import timestampToHumanDate from '../../helpers/timestampToDate';

class DeckController {
   #id;
   #eventListenersRegistered;

   async init() {
      this.#id = this.#deckId;

      await this.#renderDeckHtml();

      this.#registerEventListeners();
   }

   async #renderDeckHtml() {
      DeckView.renderDeckBaseHtml();

      try {
         const deck = await DeckModel.loadADeck(this.#id);

         // If the deck isn't theirs or doesn't exist, relocate them to the homepage
         if (deck.statusCode === 404) {
            sendMessageToNextPage('negative', deck.message);
            window.location.assign('/app.html#home');
         }

         if (deck.statusCode !== 200) throw new Error(deck.message);
         DeckView.loadDeckData(deck.data.data);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }

   // #editDeckPopup() {
   //    PopupController.show('Edit your deck', DeckView.popupHtml);
   //    DeckView.editOrDeleteDeckListener(
   //       this.#editDeck.bind(this),
   //       this.#deleteDeck.bind(this)
   //    );
   // }

   async #editDeck(e, deckForm, deckFormInputs) {
      const errorsFound = checkFormForErrors(deckFormInputs);

      if (errorsFound) return;

      const formValues = grabFormValues(deckForm);

      if (!formValues.publicToEveryone) formValues.publicToEveryone = false;
      else formValues.publicToEveryone = true;

      renderSpinner(e.target, 'white');

      try {
         const updateDeck = await DeckModel.editDeck(this.#id, formValues);

         if (updateDeck.statusCode !== 200) throw new Error(updateDeck.message);

         await waitInSeconds(1);

         // Pass true in here because we are updating data, not loading it
         DeckView.loadDeckData(updateDeck.data.data, true);
         showMessage('positive', 'Your deck has been successfully updated.');
         PopupController.close();
      } catch (err) {
         console.error(err.message);
         showMessage('negative', err.message);
      }

      e.target.textContent = 'Save';
   }

   async #deleteDeck(e) {
      renderSpinner(e.target, 'white');

      const deleteDataStatus = await DeckModel.deleteDeck(this.#id);

      if (deleteDataStatus !== 204) {
         throw new Error(deleteData.message);
      }

      await waitInSeconds(1);

      sendMessageToNextPage(
         'positive',
         'Your deck and all its cards have been successfully deleted.'
      );

      // not sure why we have to reload here, but it is what it is I guess
      // For some reason, the page doesn't "reload" on this redirect, so we manually trigger it
      window.location.assign('/app.html#home');
      window.location.reload();
   }

   async #createCardManual(createForm, formValues) {
      try {
         const card = await DeckModel.createFromManual(this.#id, formValues);
         if (card.statusCode !== 201) throw new Error(card.message);
         DeckView.cardManuallyAdded(card.data.data);

         showMessage('positive', 'Card successfully added.');
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }

      clearFormInputs(createForm);
   }

   async #createCard(e, createForm, createFormInputs) {
      const errorsFound = checkFormForErrors(createFormInputs);

      if (errorsFound) return;

      const formValues = grabFormValues(createForm);

      const formClassList = createForm.classList;

      if (formClassList.contains('manual'))
         return this.#createCardManual(createForm, formValues);

      PopupController.close();

      const usage = JSON.parse(window.localStorage.getItem('usage'));

      try {
         DeckView.notification('<p>Generating cards.</p>', 'in-progress');

         let cards, type;

         if (formClassList.contains('youtube')) {
            cards = await DeckModel.createCards(
               this.#id,
               'youtube',
               formValues
            );
            type = 'youtubeUpload';
         }

         if (formClassList.contains('pdf')) {
            const formData = new FormData(createForm);
            cards = await DeckModel.createCards(this.#id, 'pdf', formData);
            type = 'pdfUpload';
         }

         if (formClassList.contains('notes')) {
            cards = await DeckModel.createCards(this.#id, 'text', formValues);
            type = 'notes';
         }

         if (formClassList.contains('web-page')) {
            cards = await DeckModel.createCards(
               this.#id,
               'website',
               formValues
            );
            type = 'websites';
         }

         if (cards.statusCode === 429) {
            const { message } = cards.data;

            const messageEl = `<p>${message} <a class="blue-link" href="/pricing.html" target="_blank">Upgrade</a></p>`;

            DeckView.notification(messageEl, 'error');

            clearFormInputs(createForm);

            return;
         }

         if (cards.statusCode !== 200) throw new Error(cards.message);

         // Look at localStorage --> usage[pdfUpload] === usage.pdfUpload --> then increment by 1 and save back to localStorage
         usage[type].count++;

         localStorage.setItem('usage', JSON.stringify(usage));

         DeckView.notification(
            '<p>Your cards have been created! Refresh the browser to see them.</p>',
            'complete'
         );
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
         DeckView.notificationHide();
      }

      clearFormInputs(createForm);
   }

   #registerEventListeners() {
      // Add event listeners for showing popups each time a deck is loaded, since this HTML is dynamically load each time a deck is loaded
      DeckView.showPopupListeners(this.#showPopups);
      DeckView.searchEventListener();
      DeckView.studyDeckListener();
      DeckView.shareDeckListener();

      // Avoid re-registering event listeners for static elements in the HTML
      if (this.#eventListenersRegistered) return;

      // Register event listeners for popup interactions (edit and delete)
      DeckView.popupClicksListeners(
         this.#showPopups,
         this.#editDeck.bind(this),
         this.#deleteDeck.bind(this),
         this.#createCard.bind(this),
         this.#editCard.bind(this),
         this.#deleteCard.bind(this)
      );

      this.#eventListenersRegistered = true;
   }

   async #editCard(e, editCardForm, editCardInputs, idOfCard) {
      const errorsFound = checkFormForErrors(editCardInputs);

      if (errorsFound) return;

      const formValues = grabFormValues(editCardForm);

      renderSpinner(e.target, 'white');

      await waitInSeconds(1);

      try {
         const editCard = await DeckModel.editCard(idOfCard, formValues);

         if (editCard.statusCode !== 200) throw new Error(editCard.message);

         DeckView.updateCardHtml(editCard);

         PopupController.close();

         showMessage('positive', 'Your card has been successfully updated.');
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }

      e.target.textContent = 'Save';
   }

   async #deleteCard(id) {
      try {
         const deleteCard = await DeckModel.deleteCard(id);

         if (deleteCard !== 204) throw new Error(deleteCard.message);

         PopupController.close();

         showMessage('positive', 'Your card has been successfully deleted.');

         DeckView.manageDeletedCard(id);
      } catch (err) {
         console.error(err.messages);
         showMessage('negative', err.message);
      }
   }

   #showPopups(title, popupContentEl) {
      PopupController.show(title, popupContentEl);
   }

   get #deckId() {
      const [_, idOfDeck] = window.location.hash.split('/');

      return idOfDeck;
   }
}

export default new DeckController();
