import CardView from '../view/CardView';
import CardModel from '../model/CardModel';
import { sendMessageToNextPage, showMessage } from '../../helpers/messages';
import PopupController from '../../shared/controller/PopupController';
import { checkFormForErrors, grabFormValues } from '../../helpers/form';
import { renderSpinner } from '../../helpers/renderSpinner';
import waitInSeconds from '../../helpers/waitInSeconds';

class CardController {
   #eventListenersRegistered = false;
   async init() {
      await this.#renderCardHtml();

      CardView.setPoints();

      this.#registerEventListeners();

      CardView.maxHeightForDeck();
   }

   async #renderCardHtml(showingMoreCards = false) {
      // If we are showing more cards, we don't need to render the base HTML again
      if (!showingMoreCards) CardView.renderCardBaseHtml();

      try {
         const deck = await CardModel.loadADeck(this.#idOfDeck);

         if (deck.statusCode !== 200) throw new Error(deck.message);

         const deckData = deck.data.data;

         CardView.adjustHtml(deckData);

         const urgentCards = deckData.stats.urgentlyDueCards;
         const dueCards = deckData.stats.dueCards;
         const newCards = deckData.stats.newCards;

         if (
            urgentCards.results === 0 &&
            dueCards.results == 0 &&
            newCards.results === 0
         )
            return CardView.noMoreCards(this.#studyAheadPopup);

         CardView.setStudyCards(
            this.#orderCards(urgentCards, dueCards, newCards)
         );
      } catch (err) {
         console.error(err.message);
         showMessage('negative', err.message);
      }
   }

   // Orders the card from earliest reviewAt time to latest reviewAt time and then removes all of the cards except the first 10.
   #orderCards(urgentCards, dueCards, newCards) {
      let sortedCards = [];

      // We first push the urgent cards (they are studied first (review date has passed)), then the new cards, then the due cards (cards that WILL be due in 30 mins --> backend process.env.FLASHCARD_BUFFER_IN_MINUTES)
      urgentCards.cards.forEach(card => sortedCards.push(card));
      newCards.cards.forEach(card => sortedCards.push(card));
      dueCards.cards.forEach(card => sortedCards.push(card));

      return sortedCards.slice(0, 10);
   }

   async #cardIncorrect(id) {
      try {
         const res = await CardModel.cardAnswer('incorrect', id);

         if (res.statusCode !== 200) throw new Error(res.message);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }

   async #cardCorrect(id) {
      try {
         const res = await CardModel.cardAnswer('correct', id);

         if (res.statusCode !== 200) throw new Error(res.message);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }

   #studyAheadPopup(title, popupForm) {
      PopupController.show(title, popupForm);
   }

   async #studyAhead(e, studyAheadForm, studyAheadInputs) {
      const errorsFound = checkFormForErrors(studyAheadInputs);

      if (errorsFound) return;

      const formValues = grabFormValues(studyAheadForm);

      renderSpinner(e.target, 'white');

      try {
         const studyAheadRes = await CardModel.studyAhead(
            formValues,
            this.#idOfDeck
         );

         if (studyAheadRes.statusCode !== 200)
            throw new Error(studyAheadRes.message);

         sendMessageToNextPage(
            'positive',
            `You are studying ${formValues.days} ${
               formValues.days === 1 ? 'day' : 'days'
            } ahead!`
         );

         await waitInSeconds(1);

         window.location.reload();
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }

   #registerEventListeners() {
      CardView.answerBoxListeners(
         this.#cardIncorrect,
         this.#cardCorrect,
         this.#renderCardHtml.bind(this)
      );

      CardView.clickAnywhereListener();

      if (this.#eventListenersRegistered) return;

      CardView.studyAheadListener(this.#studyAhead.bind(this));

      CardView.keyDownListener();

      this.#eventListenersRegistered = true;
   }

   get #idOfDeck() {
      return window.location.hash.split('/')[1];
   }
}

export default new CardController();
