import { ROOT_URL } from '../../config';

class LeaderboardView {
   renderLeaderboardBaseHtml() {
      const middleContent = document.querySelector('section.middle');

      middleContent.innerHTML = this.#middleLeaderboardHtml;
   }

   tableButtonClickListener(handler) {
      const buttonsContainer = document.querySelector(
         'section.middle .leaderboard .buttons'
      );

      const allBtns = buttonsContainer.querySelectorAll('button');

      const activeUnderline =
         buttonsContainer.querySelector('.active-underline');

      buttonsContainer.addEventListener('click', e => {
         const clickedBtn = e.target.closest('button');

         if (!clickedBtn) return;

         activeUnderline.style.left = `${clickedBtn.offsetLeft}px`;

         allBtns.forEach(btn => btn.classList.remove('active'));

         clickedBtn.classList.add('active');

         const { filter } = clickedBtn.dataset;

         handler(filter);
      });
   }

   adjustTableHtml(users) {
      const tableBody = document.querySelector(
         'section.middle .leaderboard table tbody'
      );

      tableBody.innerHTML = '';

      users.forEach((user, index) => {
         const html = this.#tableUserHtml(user, index);

         tableBody.insertAdjacentHTML('beforeend', html);
      });
   }

   #tableUserHtml(user, index) {
      const isUser = user.user.id === window.localStorage.getItem('id');

      let trophy = '';
      if (index + 1 === 1) {
         trophy = '🏆';
      } else if (index + 1 === 2) {
         trophy = '🥈';
      } else if (index + 1 === 3) {
         trophy = '🥉';
      }
      return `<tr onclick="window.location.href='#user/${
         user.user.id
      }'" class="is-user-${isUser}">
                    <td class="rank"><p>${trophy} ${
         index + 1
      }</p></td> <!-- Added rank column -->
                    <td class="friend">
                        <img src="${
                           user.user.imageUrl
                        }" alt="Profile image of ${
         user.user.firstName
      }" class="profile-img">
                        <span><p>${user.user.firstName} ${
         user.user.lastName
      }</p></span>
                    </td>
                    <!--<td><p>${user.user.streak}</p></td>-->
                    <td class="xp">
                        <p class="table-xp">${user.experience} XP</p>
                    </td>
              </tr>`;
   }

   noFollowers() {
      const noFollowers = document.querySelector(
         'section.middle .leaderboard .not-following-anyone'
      );
      noFollowers.style.display = 'flex';
   }

   get #middleLeaderboardHtml() {
      return `
   <div class="leaderboard">
      <div class="top">
         <div class="in-line">
               <h3>Friends leaderboard</h3>
               <a href="#friends" class="btn secondary">
               <svg viewBox="-5.76 -5.76 75.52 75.52" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#000000" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><circle cx="29.22" cy="16.28" r="11.14"></circle><path d="M41.32,35.69c-2.69-1.95-8.34-3.25-12.1-3.25h0A22.55,22.55,0,0,0,6.67,55h29.9"></path><circle cx="45.38" cy="46.92" r="11.94"></circle><line x1="45.98" y1="39.8" x2="45.98" y2="53.8"></line><line x1="38.98" y1="46.8" x2="52.98" y2="46.8"></line></g></svg>
               Add friends</a>
         </div>
      </div>

      <div class="leaderboard-table">
            <div class="buttons">
                <button class="btn active" data-filter="daily"><p>Daily</p></button>
                <button class="btn" data-filter="weekly"><p>Weekly</p></button>
                <button class="btn" data-filter="monthly"><p>Monthly</p></button>
                <button class="btn" data-filter="all-time"><p>All Time</p></button>
                <div class="active-underline"></div>
            </div>

            <table>
                   <!-- <thead>
                        <tr>
                            <th class="rank"><h5>
                             <svg width="238px" height="238px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 5V19C4 19.5523 4.44772 20 5 20H19" stroke="#000000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M18 9L13 13.9999L10.5 11.4998L7 14.9998" stroke="#000000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                              Rank</h5>
                            </th>
                            <th class="friend"><h5>
                              <svg viewBox="0 0 25.00 25.00" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.0001 13.09C14.4909 13.09 16.5101 11.0708 16.5101 8.58C16.5101 6.08919 14.4909 4.07 12.0001 4.07C9.5093 4.07 7.49011 6.08919 7.49011 8.58C7.49011 11.0708 9.5093 13.09 12.0001 13.09Z" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M8.98008 11.91C8.97008 11.91 8.97008 11.91 8.96008 11.92" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M16.9701 12.82C19.5601 14.4 21.3201 17.19 21.5001 20.4C21.5101 20.69 21.2801 20.93 20.9901 20.93H3.01007C2.72007 20.93 2.49007 20.69 2.50007 20.4C2.68007 17.21 4.43007 14.43 6.99007 12.85" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg> 
                              Friend</h5>
                            </th>
                            <th class="xp"><h5>💎 XP</h5></th>
                        </tr>
                    </thead> -->
                    <tbody>
                        <tr class="skeleton-container">
                           <td class="rank"><p>🏆 1</p></td>
                           <td class="friend">
                              <span class="skeleton img"></span>
                              <span><p><span class="skeleton first"></span></p>
                           </td>
                           <td>
                              <p class="table-xp"><span class="skeleton sub"></span> XP</p>
                           </td>
                        </tr>

                         <tr class="skeleton-container">
                           <td class="rank"><p>🥈 2</p></td>
                           <td class="friend">
                              <span class="skeleton img"></span>
                              <span><p><span class="skeleton first"></span></p>
                           </td>
                           <td>
                              <p class="table-xp"><span class="skeleton sub"></span> XP</p>
                           </td>
                        </tr>

                         <tr class="skeleton-container">
                           <td class="rank"><p>🥉 3</p></td>
                           <td class="friend">
                              <span class="skeleton img"></span>
                              <span><p><span class="skeleton first"></span></p>
                           </td>
                           <td>
                              <p class="table-xp"><span class="skeleton sub"></span> XP</p>
                           </td>
                        </tr>
                    </tbody>
                </table>

                <div class="not-following-anyone" style="display: none;">
                  <p>You're a lone wolf right now! 🐺 Add friends to see how you rank!</p>
                  <a class="btn secondary" href="#friends">
                     <svg viewBox="-5.76 -5.76 75.52 75.52" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#000000" fill="none">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                           <circle cx="29.22" cy="16.28" r="11.14"></circle>
                           <path d="M41.32,35.69c-2.69-1.95-8.34-3.25-12.1-3.25h0A22.55,22.55,0,0,0,6.67,55h29.9"></path>
                           <circle cx="45.38" cy="46.92" r="11.94"></circle>
                           <line x1="45.98" y1="39.8" x2="45.98" y2="53.8"></line>
                           <line x1="38.98" y1="46.8" x2="52.98" y2="46.8"></line>
                        </g>
                     </svg>
                  Add friends
                  </a>
               </div>
      </div>
   </div>
`;
   }
}

export default new LeaderboardView();
