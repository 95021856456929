import timestampToHumanDate from '../../helpers/timestampToDate';

class ProfileView {
   renderProfileHtml() {
      const middle = document.querySelector('section.middle');
      middle.innerHTML = this.#middleProfileHtml;
   }

   saveProfileListener(handler) {
      const saveChangesBtn = document.querySelector(
         'section.middle .profile button.save-changes'
      );

      saveChangesBtn.addEventListener('click', e => {
         e.preventDefault();

         const form = e.target.closest('form');
         const formValues = form.querySelectorAll('input, textarea');

         handler(e, form, formValues);
      });
   }

   imageChangeEventListener(handler) {
      const imageInput = document.querySelector('input#imageUrl');
      const imagePreview = document.querySelector('img#profileImagePreview');
      const imageInputWrapper = document.querySelector('.image-input-wrapper');
      const profilePicOnRight = document.querySelector('section.right img');

      imageInputWrapper.addEventListener('click', () => imageInput.click());

      imageInput.addEventListener('change', async function (e) {
         const [file] = this.files;

         if (file)
            imagePreview.src = profilePicOnRight.src =
               URL.createObjectURL(file);

         handler(file);
      });
   }

   changePasswordListener(handler) {
      const savePasswordBtn = document.querySelector(
         'section.middle .profile button.save-password'
      );

      savePasswordBtn.addEventListener('click', e => {
         e.preventDefault();

         const form = e.target.closest('form');
         const formValues = form.querySelectorAll('input, textarea');

         handler(e, form, formValues);
      });
   }

   get #middleProfileHtml() {
      const dateNow = timestampToHumanDate(Date.now());
      const firstName = localStorage.getItem('firstName');
      const lastName = localStorage.getItem('lastName');
      const email = localStorage.getItem('email');
      const imageUrl = localStorage.getItem('imageUrl');
      const id = localStorage.getItem('id');
      return `
   <div class="profile">
      <div class="top">
         <div class="in-line">
               <h3>Your profile</h3>
               <a href="#user/${id}"><button class="btn secondary"> 
                  <svg viewBox="0 0 25.00 25.00" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.0001 13.09C14.4909 13.09 16.5101 11.0708 16.5101 8.58C16.5101 6.08919 14.4909 4.07 12.0001 4.07C9.5093 4.07 7.49011 6.08919 7.49011 8.58C7.49011 11.0708 9.5093 13.09 12.0001 13.09Z" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M8.98008 11.91C8.97008 11.91 8.97008 11.91 8.96008 11.92" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M16.9701 12.82C19.5601 14.4 21.3201 17.19 21.5001 20.4C21.5101 20.69 21.2801 20.93 20.9901 20.93H3.01007C2.72007 20.93 2.49007 20.69 2.50007 20.4C2.68007 17.21 4.43007 14.43 6.99007 12.85" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
               View profile </button></a>
         </div>
      </div>

      <div class="card">
        <h4>Change profile details</h4>

      <div class="input-container">
         <label for="imageUrl">Profile Picture</label>
         <div class="image-input-wrapper">
            <input type="file" id="imageUrl" name="imageUrl" value="${imageUrl}" accept="image/*" style="display: none;" />
            <img id="profileImagePreview" src="${imageUrl}" alt="Profile image preview" />
            <div class="hover">
               <svg viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            </div>
         </div>
      </div>

         <form class="profile-info">
            
            <div class="input-container">
               <label for="firstName">First Name</label>
               <input type="text" id="firstName" name="firstName" value="${firstName}" required />
                <div class="error hiddenErr">
                  <p></p>
               </div>
            </div>
            <div class="input-container">
               <label for="lastName">Last Name</label>
               <input type="text" id="lastName" name="lastName" value="${lastName}" required />
                <div class="error hiddenErr">
                  <p></p>
               </div>
            </div>
            <div class="input-container">
               <label for="email">Email</label>
               <input type="email" id="email" name="email" value="${email}" required />
                <div class="error hiddenErr">
                  <p></p>
               </div>
            </div>
            <button type="submit" class="btn primary save-changes">Save changes</button>
         </form>
        </div>

        <div class="card">
        <h4>Change password</h4>
         <form class="password-change">
            <div class="input-container">
               <label for="currentPassword">Current Password</label>
               <input type="password" id="currentPassword" name="currentPassword" required />
                <div class="error hiddenErr">
                  <p></p>
               </div>
            </div>
            <div class="input-container">
               <label for="newPassword">New Password</label>
               <input type="password" id="newPassword" name="newPassword" required />
                <div class="error hiddenErr">
                  <p></p>
               </div>
            </div>
            <div class="input-container">
               <label for="confirmNewPassword">Confirm New Password</label>
               <input type="password" id="confirmNewPassword" name="confirmNewPassword" required />
                <div class="error hiddenErr">
                  <p></p>
               </div>
            </div>
            <button type="submit" class="btn primary save-password">Save password</button>
         </form>
      </div>
      </div>
   </div>
`;
   }
}

export default new ProfileView();
