import { ROOT_URL_FRONTEND } from '../../config';
import { showMessage } from '../../helpers/messages';
import timestampToHumanDate from '../../helpers/timestampToDate';
import waitInSeconds from '../../helpers/waitInSeconds';

class DeckView {
   #currentDeck;
   #popupEl = document.querySelector('.popup');
   #editDeckForm = document.querySelector('form.edit-deck');
   #deleteDeckForm = document.querySelector('form.delete-deck');
   #addCardForm = document.querySelector('form.add-card');
   #addYoutubeForm = document.querySelector('form.youtube');
   #addWebpageForm = document.querySelector('form.web-page');
   #addPdfForm = document.querySelector('form.pdf');
   #addNotesForm = document.querySelector('form.notes');
   #addFrontBackForm = document.querySelector('form.manual');
   #editCardForm = document.querySelector('form.edit-card');
   #deleteCardForm = document.querySelector('form.delete-card');

   renderDeckBaseHtml() {
      const middleContent = document.querySelector('section.middle');
      middleContent.innerHTML = this.#deckBaseHtml;
   }

   // Load deck data into the view.
   loadDeckData(deck, updatingData = false) {
      this.#currentDeck = deck;

      const {
         name,
         createdAt,
         color,
         publicToEveryone,
         description,
         cards,
         stats,
      } = this.#currentDeck;

      const deckEl = document.querySelector('.deck .top-page h3');
      const descriptionEl = document.querySelector(
         '.deck .top-page p.description span'
      );
      const cardEl = document.querySelector('.deck .card');
      const cardListEl = cardEl.querySelector('ul');
      const totalCardsEl = document.querySelector('.total-cards span');
      const reviewCardsEl = document.querySelector('.review-cards span');
      const newCardsEl = document.querySelector('.new-cards span');
      const deckNameInput = document.querySelector('input#name');
      const publicToEveryoneInput = document.querySelector(
         'input#publicToEveryone'
      );
      const deckDescriptionInput = document.querySelector(
         'textarea#description'
      );
      // Select the radio button with the matching color value
      const colorInput = document.querySelector(
         `input[type="radio"][name="color"][value="${color}"]`
      );

      if (colorInput) colorInput.checked = true;

      const colorPrev = document.querySelector(
         'section.middle .deck .top-page .color-prev'
      );

      deckEl.textContent = deckNameInput.value = name;
      publicToEveryoneInput.checked = publicToEveryone;
      descriptionEl.textContent = deckDescriptionInput.value = description;
      colorPrev.style.background = color;

      if (updatingData) return;

      totalCardsEl.textContent = stats.totalCards.results;
      reviewCardsEl.textContent =
         stats.dueCards.results + stats.urgentlyDueCards.results;
      newCardsEl.textContent = stats.newCards.results;

      // The study cards button
      const studyDeckBtn = document.querySelector(
         'section.middle .deck .top-page .right button'
      );

      const topOfCards = document.querySelector(
         'section.middle .deck .card .top-of-card'
      );

      studyDeckBtn.setAttribute('href', `#cards/${this.#currentDeck._id}`);

      cardListEl.innerHTML = '';

      if (stats.totalCards.results === 0) {
         studyDeckBtn.textContent = 'Add cards to study';
         studyDeckBtn.classList.add('need-to-add-cards');
         return;
      }

      cards.forEach(card => {
         console.log(timestampToHumanDate(card.reviewAt));
         const html = this.#generateCardHtml(card);
         cardListEl.insertAdjacentHTML('beforeend', html);
      });

      studyDeckBtn.classList.remove('need-to-add-cards');
   }

   cardManuallyAdded(card) {
      const cardEl = document.querySelector('.deck .card');
      const cardListEl = cardEl.querySelector('ul');
      const html = this.#generateCardHtml(card);
      cardListEl.insertAdjacentHTML('beforeend', html);

      const totalCardsEl = document.querySelector('.total-cards span');
      const newCardsEl = document.querySelector('.new-cards span');

      totalCardsEl.textContent = +totalCardsEl.textContent + 1;
      newCardsEl.textContent = +newCardsEl.textContent + 1;

      const studyDeckBtn = document.querySelector(
         'section.middle .deck .top-page .right button'
      );
      const addCardsCardBtn = document.querySelector(
         'section.middle .deck .card .add-cards'
      );
      const topOfCards = document.querySelector(
         'section.middle .deck .card .top-of-card'
      );

      studyDeckBtn.classList.remove('need-to-add-cards');
      studyDeckBtn.textContent = 'Study deck';
      // addCardsCardBtn.style.display = 'none';
      // topOfCards.style.display = 'block';
      cardEl.style.gap = '30px';
   }

   studyDeckListener() {
      const studyDeckBtn = document.querySelector(
         'section.middle .deck .top-page .right button'
      );

      studyDeckBtn.addEventListener('click', e => {
         const userNeedsToAddCards =
            e.target.classList.contains('need-to-add-cards');

         if (userNeedsToAddCards)
            return document.querySelector('.add-cards').click();

         const href = e.target.getAttribute('href');
         window.location.assign(`${href}`);
      });
   }

   #generateCardHtml(card) {
      const {
         front,
         back,
         isNew,
         inReview,
         futureReview,
         _id,
         reviewAt,
         correctCount,
         incorrectCount,
      } = card;
      return `<li class="is-new-${isNew} in-review-${inReview} future-review-${futureReview}" data-id=${_id}>
                  ${isNew ? '<div class="widget"><p>New</p></div>' : ''}
                  ${inReview ? '<div class="widget"><p>Due</p></div>' : ''}
                  <!-- ${
                     futureReview
                        ? '<div class="widget"><p>Future</p></div>'
                        : ''
                  } -->
                  <p class="front bold">${front}</p>
                  <p class="back">${back}</p>
                  <button class="popup-button edit-card"><p>•</p><p>•</p><p>•</p></button>
              </li>`;
   }

   // <p class="review-next subtext">Next review:
   //                   ${isNew ? 'Card is new' : ''}
   //                   ${inReview ? timestampToHumanDate(reviewAt, false) : ''}
   //                   ${
   //                      futureReview
   //                         ? timestampToHumanDate(reviewAt, false)
   //                         : ''
   //                   }
   //                </p>

   // Class options are in-progress and complete
   notification(message, classOp) {
      const messageEl = document.querySelector('.notification');
      messageEl.innerHTML = message;
      messageEl.classList = 'notification';
      messageEl.classList.add(classOp);
   }

   notificationHide() {
      const messageEl = document.querySelector('.notification');
      messageEl.classList = 'hidden notification';
   }

   #showUsage() {
      const subscription = JSON.parse(
         window.localStorage.getItem('subscription')
      );

      const { pdfUpload, websites, youtubeUpload, notes } = JSON.parse(
         window.localStorage.getItem('usage')
      );

      const youTubeCount = document.querySelectorAll(
         'button.import.youtube p.count span'
      );

      const websiteCount = document.querySelectorAll(
         'button.import.web-page p.count span'
      );

      const notesCount = document.querySelectorAll(
         'button.import.notes p.count span'
      );

      const pdfCount = document.querySelectorAll(
         'button.import.pdf p.count span'
      );

      youTubeCount[0].textContent = youtubeUpload.count;
      youTubeCount[1].textContent = youtubeUpload.maxCount;

      websiteCount[0].textContent = websites.count;
      websiteCount[1].textContent = websites.maxCount;

      notesCount[0].textContent = notes.count;
      notesCount[1].textContent = notes.maxCount;

      pdfCount[0].textContent = pdfUpload.count;
      pdfCount[1].textContent = pdfUpload.maxCount;
   }

   showPopupListeners(showPopups) {
      // Show edit deck popup
      document
         .querySelector('.popup-button.edit-deck')
         .addEventListener('click', () => {
            showPopups('Edit your deck', this.#editDeckForm);
         });

      // Show add cards popup
      document.querySelectorAll('.add-cards').forEach(btn =>
         btn.addEventListener('click', () => {
            this.#showUsage();
            showPopups('Add cards', this.#addCardForm);
         })
      );

      // When a user clicks the edit card button (on each card)
      document
         .querySelector('section.middle .deck ul.card-list')
         .addEventListener('click', e => {
            const button = e.target.closest('button');

            if (!button) return;

            const card = button.closest('li');
            const frontText = card.querySelector('.front').textContent;
            const backText = card.querySelector('.back').textContent;

            document.querySelector('#edit-front').value = frontText;
            document.querySelector('#edit-back').value = backText;

            this.#editCardForm.setAttribute('data-id', card.dataset.id);

            showPopups('Edit card', this.#editCardForm);
         });
   }

   updateCardHtml(card) {
      const cardData = card.data.data;
      const { _id: id, front, back } = cardData;

      const [cardToUpdate] = [
         ...document.querySelectorAll('section.middle .deck .card ul li'),
      ].filter(card => card.dataset.id === id);

      cardToUpdate.querySelector('.front').textContent = front;
      cardToUpdate.querySelector('.back').textContent = back;
   }

   async manageDeletedCard(id) {
      const [cardToDelete] = [
         ...document.querySelectorAll('section.middle .deck .card ul li'),
      ].filter(card => card.dataset.id === id);

      cardToDelete.classList.add('delete');

      await waitInSeconds(1);

      const cardIsNew = cardToDelete.classList.contains('is-new-true');
      const totalCardsEl = document.querySelector('.total-cards span');
      const reviewCardsEl = document.querySelector('.review-cards span');
      const newCardsEl = document.querySelector('.new-cards span');

      totalCardsEl.textContent = +totalCardsEl.textContent - 1;

      if (cardIsNew) {
         newCardsEl.textContent = +newCardsEl.textContent - 1;
      } else {
         reviewCardsEl.textContent = +reviewCardsEl.textContent - 1;
      }

      cardToDelete.remove();
   }

   popupClicksListeners(
      showPopups,
      editDeck,
      deleteDeck,
      createCard,
      editCard,
      deleteCard
   ) {
      this.#popupEl.addEventListener('click', e => {
         const btn = e.target.closest('button');

         if (btn && btn.classList.contains('btn')) {
            e.preventDefault();

            // Show the delete deck form
            if (btn.classList.contains('delete-deck'))
               return showPopups('Delete your deck', this.#deleteDeckForm);

            // Go back to the edit deck form when clicking go back on the delete form
            if (btn.classList.contains('go-back-delete-deck'))
               return showPopups('Edit your deck', this.#editDeckForm);

            // On the delete form, the user clicks "delete" --> deletes deck and cards
            if (btn.classList.contains('confirm-delete-deck'))
               return deleteDeck(e);

            // On the edit form, the user clicks "save" --> deck is saved
            if (btn.classList.contains('edit-deck'))
               return editDeck(
                  e,
                  this.#editDeckForm,
                  this.#editDeckForm.querySelectorAll('input')
               );

            // The Add cards popup ------>
            // The Add cards popup ------>
            // The Add cards popup ------>

            // Show create cards from youtube link form
            if (btn.classList.contains('youtube'))
               return showPopups('Add YouTube', this.#addYoutubeForm);

            // Show create cards from a web page url form
            if (btn.classList.contains('web-page'))
               return showPopups('Add Web-page', this.#addWebpageForm);

            // Show create cards from uploading a pdf  form
            if (btn.classList.contains('pdf'))
               return showPopups('Add PDF', this.#addPdfForm);

            // Show create cards from notes form
            if (btn.classList.contains('notes'))
               return showPopups('Add Notes', this.#addNotesForm);

            // Show create cards from manual entry form
            if (btn.classList.contains('manual'))
               return showPopups('Add Front-Back', this.#addFrontBackForm);

            // When the user clicks go back on any of the above forms
            if (btn.classList.contains('go-back-add-cards')) {
               return showPopups('Add cards', this.#addCardForm);
            }

            if (btn.classList.contains('create-card')) {
               const form = btn.closest('form');
               createCard(e, form, form.querySelectorAll('input, textarea'));
            }

            // The Edit cards popup ------>
            // The Edit cards popup ------>
            // The Edit cards popup ------>

            if (btn.classList.contains('save-card')) {
               const form = btn.closest('form');
               const cardId = this.#editCardForm.dataset.id;
               editCard(
                  e,
                  form,
                  form.querySelectorAll('input, textarea'),
                  cardId
               );
            }

            if (btn.classList.contains('delete-card'))
               showPopups('Delete your card', this.#deleteCardForm);

            if (btn.classList.contains('go-back-delete-card'))
               showPopups('Edit card', this.#editCardForm);

            if (btn.classList.contains('confirm-delete-card')) {
               const cardId = this.#editCardForm.dataset.id;
               deleteCard(cardId);
            }
         }
      });
   }

   searchEventListener() {
      const searchInput = document.querySelector('section.middle .deck input');

      searchInput.addEventListener('input', e => {
         const allCards = document.querySelectorAll(
            'section.middle .deck .card ul.card-list li'
         );
         const value = e.target.value.toLowerCase();
         let count = 0;

         allCards.forEach(card => {
            const frontEl = card.querySelector('.front');
            const backEl = card.querySelector('.back');
            const frontText = frontEl.textContent.toLowerCase();
            const backText = backEl.textContent.toLowerCase();

            if (!frontText.includes(value) && !backText.includes(value)) {
               card.style.display = 'none';
               return;
            }

            count++;
            card.style.display = 'block';

            // Highlight the matching text
            this.#highlightText(frontEl, value);
            this.#highlightText(backEl, value);
         });
      });
   }

   #highlightText(element, text) {
      const textContent = element.textContent;

      const updatedText = textContent.replaceAll(
         new RegExp(text, 'gi'),
         match => `<span class="searched-for">${match}</span>`
      );

      element.innerHTML = updatedText;
   }

   shareDeckListener() {
      const shareDeckBtn = document.querySelector(
         'section.middle .deck .right svg'
      );

      shareDeckBtn.addEventListener('click', () => {
         const { id: deckId, name } = this.#currentDeck;
         const userId = window.localStorage.getItem('id');
         const shareUrl = `Hey! Check out my deck, "${name}". You can download it automatically using this link: ${ROOT_URL_FRONTEND}/app.html#user/${userId}?sharedDeck=${deckId}`;
         navigator.clipboard.writeText(shareUrl);
         showMessage('positive', 'Share-deck URL copied successfully!');
      });
   }

   get #deckBaseHtml() {
      return `
   <div class="deck">
      <div class="top-page">
            <div class="left">
               <div class="title-color">
                  <div class="color-prev"></div>
                  <h3><span class="skeleton skeleton-container"></span></h3>
                  <button class="popup-button edit-deck"><p>•</p><p>•</p><p>•</p>
                </button>
               </div>
               <p class="subtext description"><span><span class="skeleton"></span> <span class="skeleton sub"></span></span></p>
               
            </div>
  
            <div class="right">
            <svg class="share-deck" viewBox="-0.96 -0.96 25.92 25.92" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Communication / Share_iOS_Export"> <path id="Vector" d="M9 6L12 3M12 3L15 6M12 3V13M7.00023 10C6.06835 10 5.60241 10 5.23486 10.1522C4.74481 10.3552 4.35523 10.7448 4.15224 11.2349C4 11.6024 4 12.0681 4 13V17.8C4 18.9201 4 19.4798 4.21799 19.9076C4.40973 20.2839 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07899 21 7.19691 21H16.8036C17.9215 21 18.4805 21 18.9079 20.7822C19.2842 20.5905 19.5905 20.2839 19.7822 19.9076C20 19.4802 20 18.921 20 17.8031V13C20 12.0681 19.9999 11.6024 19.8477 11.2349C19.6447 10.7448 19.2554 10.3552 18.7654 10.1522C18.3978 10 17.9319 10 17 10" stroke="#000000" stroke-width="1.08" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
               <button href="" class="btn rainbow-button">Study deck</button>
            </div>
      </div>

      <ul class="top">
         <li>
            <h4 class="stat review-cards">
           <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V12.5L14 14.5M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z" stroke="#000000" stroke-width="1.32" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            <span><span class="skeleton-container skeleton"></span></span></h4>
            <p class="subtext">Cards to review</p>
            </li>
         <li>
          <h4 class="stat total-cards">
         <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-2.56 -2.56 37.12 37.12" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <style type="text/css"> .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;} </style> <path class="st0" d="M18,29H8c-2.2,0-4-1.8-4-4V7c0-2.2,1.8-4,4-4h10c2.2,0,4,1.8,4,4v18C22,27.2,20.2,29,18,29z"></path> <path class="st0" d="M22,6.4l5,3c1.9,1.1,2.5,3.6,1.3,5.5L22,25.5"></path> <path class="st0" d="M28,16l0.5,0.7c1.3,1.8,0.8,4.3-1,5.6l-5.7,4"></path> </g></svg>
          <span><span class="skeleton-container skeleton"></span></span></h4>
            <p class="subtext">Total cards</p>
            </li>
         <li>
            <h4 class="stat new-cards">
           <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-2.56 -2.56 37.12 37.12" xml:space="preserve" fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <style type="text/css"> 
                    .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;} 
                  </style> 
                  <path class="st0" d="M18,29H8c-2.2,0-4-1.8-4-4V7c0-2.2,1.8-4,4-4h10c2.2,0,4,1.8,4,4v18C22,27.2,20.2,29,18,29z"></path> 
                  <path class="st0" d="M22,6.4l5,3c1.9,1.1,2.5,3.6,1.3,5.5L22,25.5"></path> 
                  <path class="st0" d="M28,16l0.5,0.7c1.3,1.8,0.8,4.3-1,5.6l-5.7,4"></path>
                  <!-- Plus sign -->
                  <line x1="13" y1="12" x2="13" y2="18" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <line x1="10" y1="15" x2="16" y2="15" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>
            <span><span class="skeleton-container skeleton"></span></span></h4>
            <p class="subtext">New cards</p>
            </li>
      </ul>
             
      <p class="notification hidden">This is my notification</p>

      <div class="input-with-icon">
         <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z" fill="#000"></path> </g></svg>
         <input type="text" placeholder="Search your cards">
      </div>
           
      <div class="card">
         <div class="top-of-card">
            <h4>Cards</h4>
            <button class="add-cards btn secondary">
            <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-2.56 -2.56 37.12 37.12" xml:space="preserve" fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <style type="text/css"> 
                    .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;} 
                  </style> 
                  <path class="st0" d="M18,29H8c-2.2,0-4-1.8-4-4V7c0-2.2,1.8-4,4-4h10c2.2,0,4,1.8,4,4v18C22,27.2,20.2,29,18,29z"></path> 
                  <path class="st0" d="M22,6.4l5,3c1.9,1.1,2.5,3.6,1.3,5.5L22,25.5"></path> 
                  <path class="st0" d="M28,16l0.5,0.7c1.3,1.8,0.8,4.3-1,5.6l-5.7,4"></path>
                  <!-- Plus sign -->
                  <line x1="13" y1="12" x2="13" y2="18" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
                  <line x1="10" y1="15" x2="16" y2="15" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
                </g>
              </svg>
            Add cards</button>
          </div>
          <button class="btn rainbow-button add-cards" style="display: none;">Add cards</button>
          <ul class="card-list">
          
          <li class="skeleton-container">
                  <p class="front bold"><span class="skeleton sub"></span></p>
                  <p class="back"><span class="skeleton"></span><span class="skeleton"></span><span class="skeleton"></span><span class="skeleton sub"></span></p>
              </li>

              <li class="skeleton-container">
                  <p class="front bold"><span class="skeleton sub"></span></p>
                  <p class="back"><span class="skeleton"></span><span class="skeleton"></span><span class="skeleton"></span><span class="skeleton sub"></span></p>
              </li>

              <li class="skeleton-container">
                  <p class="front bold"><span class="skeleton sub"></span></p>
                  <p class="back"><span class="skeleton"></span><span class="skeleton"></span><span class="skeleton"></span><span class="skeleton sub"></span></p>
              </li>

              <li class="skeleton-container">
                  <p class="front bold"><span class="skeleton sub"></span></p>
                  <p class="back"><span class="skeleton"></span><span class="skeleton"></span><span class="skeleton"></span><span class="skeleton sub"></span></p>
              </li>
          
          </ul>
      </div>
   </div>
`;
   }
}

export default new DeckView();
