import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import formatLargeNumbers from './formatLargeNumbers';

const loadGraph = (graphEl, data) => {
   const currUserData = data.data.map(data => data.currUser.experience);
   const versusUserData = data.data.map(data => data.versusUser.experience);
   const currUserTotalXp = formatLargeNumbers(
      currUserData.reduce((acc, cur) => acc + cur, 0)
   );
   const versusUserTotalXp = formatLargeNumbers(
      versusUserData.reduce((acc, cur) => acc + cur, 0)
   );

   return new Chart(graphEl, {
      type: 'line',
      data: {
         labels: labelGeneration(),
         datasets: [
            {
               label: `You: ${currUserTotalXp}`,
               data: currUserData,
               borderWidth: 1.5,
               tension: 0.4,
               borderColor: '#837DD8',
            },
            {
               label: `${data.versusName}: ${versusUserTotalXp}`,
               data: versusUserData,
               borderWidth: 1.5,
               tension: 0.4,
               borderColor: '#0000003a',
            },
         ],
      },
      options: {
         scales: {
            y: {
               beginAtZero: true,
            },
         },
         plugins: {
            legend: {
               display: false,
            },
            tooltip: {
               callbacks: {
                  label: function (context) {
                     return `${context.dataset.label.split(':')[0]}: ${
                        context.parsed.y
                     } XP`;
                  },
                  labelColor: function (context) {
                     return {
                        backgroundColor: context.dataset.borderColor,
                        borderColor: '#000000',
                     };
                  },
               },
            },
         },
      },
   });
};

const labelGeneration = () => {
   const dayIndex = new Date().getDay();
   const arr = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
   arr[dayIndex] = `🎯`;
   return arr;
};

const generateLegend = (chart, legendEl) => {
   const datasets = chart.data.datasets;
   let legendHtml = '<ul class="custom-legend">';
   datasets.forEach((dataset, i) => {
      legendHtml += `
            <li>
               <div class="left-side">
                     <p class="legend-color" style="background-color:${
                        dataset.borderColor
                     }"></p>
                     <p class="legend-label">${dataset.label.split(':')[0]}</p>
               </div>

                  <p class="legend-value bold" style="color:${
                     dataset.borderColor
                  }">${dataset.label.split(':')[1]} XP</p>
            </li>`;
   });
   legendHtml += '</ul>';

   legendEl.innerHTML = legendHtml;
};

export { loadGraph, generateLegend };
