const timestampToHumanDate = (timestamp, includeYear = true) => {
   const locale = navigator.language;

   const options = {
      // minute: 'numeric',
      // hour: 'numeric',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      // hour12: true,
   };

   if (!includeYear) delete options.year;

   const date = new Date(timestamp);

   return new Intl.DateTimeFormat(locale, options).format(date);
};

export default timestampToHumanDate;
