import { showMessage } from '../../helpers/messages';
import FriendsModel from '../model/FriendsModel';
import FriendsView from '../view/FriendsView';

class FriendsController {
   init() {
      FriendsView.renderFriendsBaseHtml();
      FriendsView.inviteLinkListener();
      FriendsView.searchListener(this.#searchingForUsers);
      FriendsView.userClickListener(this.#followUnfollow);
   }

   async #followUnfollow(id, type) {
      try {
         const followUnfollow = await FriendsModel.followUnfollow(id, type);

         if (followUnfollow.statusCode !== 200)
            throw new Error(followUnfollow.message);

         console.log(followUnfollow);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }

   async #searchingForUsers(searchVal) {
      try {
         const users = await FriendsModel.getUsers(searchVal);

         if (users.statusCode !== 200) throw new Error(users.message);

         FriendsView.loadUsers(users.data, users.isFollowingIds, searchVal);

         console.log(users);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }
}

export default new FriendsController();
