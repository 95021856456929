import userIsLoggedIn from '../../helpers/userIsLoggedIn';
import { sendMessageToNextPage, showMessage } from '../../helpers/messages';
import appNavBtns from '../../helpers/appNavBtns';
import AppView from '../view/AppView';
import AppModel from '../model/AppModel';
import HomeController from './HomeController';
import DeckController from './DeckController';
import CardController from './CardController';
import ProfileController from './ProfileController';
import LeaderboardController from './LeaderboardController';
import UserController from './UserController';
import FriendsController from './FriendsController';
import { renderSpinner } from '../../helpers/renderSpinner';
import waitInSeconds from '../../helpers/waitInSeconds';
import PopupController from '../../shared/controller/PopupController';
import adjustMainLayout from '../../helpers/adjustMainLayout';
import BurgerController from '../../shared/controller/BurgerController';

class AppController {
   async init() {
      const userLoggedIn = await userIsLoggedIn(false);

      if (!userLoggedIn) {
         // If we get here the user is logged out
         window.location.assign('/login.html');

         return;
      }

      BurgerController.init();

      this.#redirectUser();

      window.addEventListener('hashchange', this.#redirectUser.bind(this));

      this.#registerEventListeners();

      AppView.loadRightSideOfDashboard();

      this.#adjustGraphFriendSelection();

      AppView.adjustProfilePath();
   }

   async #adjustGraphFriendSelection() {
      try {
         const data = await AppModel.getFriends();

         if (data.statusCode !== 200) throw new Error(data.message);

         if (data.data.following.length === 0) return AppView.hideGraph();

         AppView.renderGraphFriends(data.data.following);

         // Select a random user from who they are following, and show that user on the graph
         const randomIndex = Math.floor(
            Math.random() * data.data.following.length
         );

         const randomUser = data.data.following[randomIndex];

         this.#loadWeeklyVersus(randomUser.isFollowing.id);

         AppView.startingPointForGraph(randomUser);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }

   #registerEventListeners() {
      AppView.logOutListener(this.#logUserOut);
      AppView.createDeck(this.#createDeck);
      AppView.friendSelectorGraph(this.#loadWeeklyVersus);
   }

   async #loadWeeklyVersus(id) {
      try {
         const data = await AppModel.loadWeeklyVersus(id);

         if (data.statusCode !== 200) throw new Error(data.message);

         AppView.weeklyVersusGraph(data);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }

   #redirectUser() {
      userIsLoggedIn();

      adjustMainLayout();

      PopupController.close();

      BurgerController.close();

      // i.e. #home, #public-decks, #help, #analytics to home, public-decks, help, analytics
      const hash = window.location.hash.replace('#', '');

      // Loads the page
      this.#renderCorrectPage(hash);

      // Scroll to the top of the page
      window.scrollTo(0, 0);

      // If the hash included decks or cards, then we aren't navigating on the nav bar (so no need to make any active button)
      const hashIncludesNonNavPages =
         hash.startsWith('decks') ||
         hash.startsWith('cards') ||
         hash.startsWith('user');

      // Adjusts the active button in the nav menu
      appNavBtns(hash, hashIncludesNonNavPages);
   }

   #renderCorrectPage(hash) {
      // Render the correct page
      switch (hash) {
         case 'home':
            HomeController.init();
            break;
         case 'help':
            // renderHelp();
            break;
         case 'analytics':
            // renderAnalytics();
            break;
         case 'profile':
            ProfileController.init();
            break;
         case 'leaderboard':
            LeaderboardController.init();
            break;
         case 'friends':
            FriendsController.init();
            break;
         default:
            if (hash.includes('decks')) return DeckController.init();

            if (hash.includes('cards')) return CardController.init();

            if (hash.includes('user')) return UserController.init();

            const incorrectPath = `${window.location.pathname}${window.location.hash}`;

            sendMessageToNextPage(
               'negative',
               `Couldn't find the page you were looking for: ${incorrectPath}`
            );

            window.location.assign('/app.html#home');

         // window.location.reload();
         // renderError();
      }
   }

   async #logUserOut(e) {
      renderSpinner(e.target, 'blue');

      try {
         const logOut = await AppModel.logUserOut();

         if (logOut.statusCode !== 200) throw new Error(logOut);

         AppModel.removeUserLocalStorage();

         await waitInSeconds(1);
         sendMessageToNextPage('positive', 'You have successfully logged out!');
         window.location.href = '/';
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }

   async #createDeck() {
      try {
         const newDeck = await AppModel.createDeck();

         if (newDeck.statusCode !== 201) throw new Error(newDeck.message);

         window.location.assign(`#decks/${newDeck.data.data._id}`);
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }
}

export default new AppController();
