import { API_URL } from '../config';
import { getJSON } from './requests';

const cloudinarySigned = async () => {
   const cloudinarySignatureUrl = `${API_URL}/cloudinary/signature`;
   const signature = await getJSON(cloudinarySignatureUrl);
   return signature;
};

export default cloudinarySigned;
