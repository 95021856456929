import { API_URL } from '../../config';
import { getJSON, patchJSON, postJSON } from '../../helpers/requests';

class CardModel {
   async loadADeck(id) {
      const getADeckUrl = `${API_URL}/decks/${id}`;
      const deck = await getJSON(getADeckUrl);
      return deck;
   }

   async cardAnswer(correctOrIncorrect, cardId) {
      const correctIncorrectUrl = `${API_URL}/cards/${cardId}/${correctOrIncorrect}`;
      const card = await patchJSON(correctIncorrectUrl);
      return card;
   }

   async studyAhead(daysAhead, deckId) {
      const studyAheadUrl = `${API_URL}/decks/${deckId}/cards/study-ahead`;
      const res = await postJSON(studyAheadUrl, daysAhead);
      return res;
   }
}

export default new CardModel();
