const formatLargeNumbers = number => {
   const formattedNumber = number.toString();
   if (number < 10_000) return formattedNumber;

   if (number >= 10_000 && number <= 99_999) {
      const [firstDigit, secondDigit, thirdDigit, fourthDigit] =
         formattedNumber;
      return `${firstDigit}${secondDigit}.${thirdDigit}${fourthDigit}k`;
   }

   if (number > 99_999 && number <= 999_999) {
      const [firstDigit, secondDigit, thirdDigit, fourthDigit] =
         formattedNumber;
      return `${firstDigit}${secondDigit}${thirdDigit}.${fourthDigit}k`;
   }

   if (number > 999_999 && number <= 9_999_999) {
      const [firstDigit, secondDigit, thirdDigit] = formattedNumber;
      return `${firstDigit}.${secondDigit}${thirdDigit}m`;
   }

   if (number > 9_999_999 && number <= 99_999_999) {
      const [firstDigit, secondDigit, thirdDigit, fourthDigit] =
         formattedNumber;
      return `${firstDigit}${secondDigit}.${thirdDigit}${fourthDigit}m`;
   }

   if (number > 99_999_999 && number <= 999_999_999) {
      const [firstDigit, secondDigit, thirdDigit, fourthDigit] =
         formattedNumber;
      return `${firstDigit}${secondDigit}${thirdDigit}.${fourthDigit}m`;
   }
};

export default formatLargeNumbers;
