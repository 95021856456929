// Adjusts the active class of the nav buttons
const appNavBtns = (hash, hashIncludesDeckOrUser) => {
   const navigateBtns = document.querySelectorAll('.left li a');

   // Remove active class from all buttons
   navigateBtns.forEach(btn => {
      btn.classList.remove('active');
   });

   console.log(hash, hashIncludesDeckOrUser);
   // If we are going inside a deck, we don't need to adjust any active class
   if (hashIncludesDeckOrUser) return;

   //i.e. querySelector('.left .home')
   const btn = document.querySelector(`.left .${hash}`);

   // Add active class to the clicked button
   btn.classList.add('active');
};

export default appNavBtns;
