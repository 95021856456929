import { getJSON, postJSON } from '../../helpers/requests';
import { API_URL } from '../../config';

class HomeModel {
   async loadDecks() {
      const allDecksUrl = `${API_URL}/decks`;

      const decks = await getJSON(allDecksUrl);

      return decks;
   }

   async searchDecks(value) {
      const searchDeckUrl = `${API_URL}/decks?search=${value}`;

      const getDecks = await getJSON(searchDeckUrl);

      return getDecks;
   }

   async createDeck() {
      const createDeckUrl = `${API_URL}/decks`;

      const postDeck = await postJSON(createDeckUrl, {
         name: 'Untitled Deck',
         description: 'This is my super cool description!',
      });

      return postDeck;
   }
}

export default new HomeModel();
